const INIT_STATE = {
    code: null,
    code_position: "head",
    tab: "integrations",
}
const integrationCustomCodeReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CUSTOM_CODE':
            return action.payload 
        case 'RESET_CUSTOM_CODE':
            return INIT_STATE
        default:
            return state
    }
}
export default integrationCustomCodeReducer