import React, { useRef, useEffect, forwardRef } from 'react';

const AutoResizeTextarea = forwardRef((props, ref) => {
    const { value, onChange, minHeight="32px", customClass="", id="", placeholder="", defaultClass="zaapGlobal-input", onKeyDown=null, name } = props;
    const textareaRef = useRef(null);

    const resizeTextarea = () => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        // Reset height to get the correct scrollHeight
        textarea.style.height = minHeight;

        // Set new height based on content
        const scrollHeight = textarea.scrollHeight;
        textarea.style.height = `${scrollHeight}px`;
    };

    // Resize on value change
    useEffect(() => {
        resizeTextarea();
    }, [value]);

    const handleChange = (e) => {
        onChange?.(e);
        resizeTextarea();
    };

    return (
        <textarea
            placeholder={placeholder}
            ref={(textarea) => {
                textareaRef.current = textarea;  // Assign to both internal ref and forwarded ref
                if (ref) ref.current = textarea;  // Forward ref to parent
            }}
            value={value}
            onChange={handleChange}
            className={`${defaultClass} ${customClass}`}
            onKeyDown={onKeyDown}
            name={name}
            
        />
    );
});

export default AutoResizeTextarea;