import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as $ from "jquery"
// import { basePath } from "../../../routes/mainRoutes"
import { Modal } from "react-bootstrap"
import TabContent from "../page-editor/customize-page/tab-content"
import Loader, { hideLoader, showLoader } from "../loader"
import { useDispatch, useSelector } from "react-redux"
import ModalErrors from "../errors/modal-errors"
import { toast } from "react-toastify"
import { apiUrl } from "../../utils/url"
import customizeDomainsIcon from "../../assets/img/modals/customize-modal-domains-icon.svg"
import { audiencePosts as apiService } from "../../services/postRequests/audience";
import CustomDomainDotModal from "../../components/page-editor/block-editor/sub-modals/customDomainDotModal";
import Teammoadal from "../teammember/team_modal";
import CustomDomainConnectedModal from "../page-editor/block-editor/sub-modals/customDomainConnectedModal";
import AlertMessage from "../../services/AlertMessage";
import { useAlert } from "../../contexts/alertContext";
import DomainModal from "../domain/domain-modal";
import dotsIcon from "../../assets/img/dots.svg"
import DomainStatus from "../domain/domain-status";
import exclamatory from "../../assets/img/rounded-exclamatory.svg"
import pending from "../../assets/img/rounded-pending.svg"
import checked from "../../assets/img/rounded-check.svg"
import settings from "../../assets/img/modals/blue-filled-gear.svg"
import NewConfirmationModal from "../common/new-confirmation-modal";
import deleteIcon from "../../assets/img/products/removeicon.svg";

const Domains = ({ setActivePage }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [error, setError] = useState([])
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const value = useSelector(state => state?.customizeDomainsReducer);
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const [isdomainConnect, setisdomainConnect] = useState(value?.connected ? true : false);
    const showCustomDomainDotsModal = useSelector(state => state?.domainDotModalShowReducer);
    const [showModalasd, setShowModalasd] = useState(false)
    const isEligible = useSelector(state => state?.eligibilityReducer);
    const [showDomainModal, setShowDomainModal] = useState(false);
    const [statusData, setStatusData] = useState({ icon: exclamatory, title: 'DNS Verification Not Started', desc: `Visit the DNS settings page of your DNS provider and add the DNS records listed below to the records section.`, bgColor: 'rgba(12, 120, 247, 0.13)' });
    const { setAlert } = useAlert();
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null, callback: null });

    useEffect(() => {
        if (!pro) {
            setShowModalasd(true);
        } else {
            setShowModalasd(false);
        }
    }, [pro, planType]);
    const handleChange = (e) => dispatch({ type: "SET_CUSTOMIZE_DOMAINS", payload: { ...value, [e.target.name]: e.target.value.toLowerCase() } })
    const handleSubmit = (e, values) => {
        if (pro) {
            e.preventDefault()
            showLoader()
            // setTimeout(() => {
            //     dispatch({
            //         type: "SET_DOMAIN_MODAL_SHOW", payload: {
            //             dotModal: true,
            //             connectedModal: false,
            //         }
            //     });
            //     // hideLoader()
            // }, 200);
            setError([]);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            const formValues = { ...values, project: slug };

            for (const key in formValues) {
                if (Object.hasOwnProperty.call(formValues, key)) {
                    formdata.append(key, formValues[key]);
                }
            }

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(apiUrl + "/project/settings/update", requestOptions)
                .then(async (res) => {
                    const response = await res?.json();
                    hideLoader()
                    if (response?.status_code == 200) {
                        dispatch({ type: "SET_CUSTOMIZE_DOMAINS", payload: { ...value, domain: values?.domain, connected: true, domainStatus: 'not_verified' } })
                        setShowDomainModal(false)
                        setisdomainConnect(true)
                        setAlert({ type: 'success', title: response?.status_message })
                        // toast.success(response?.status_message);
                    }

                    else {
                        let errorList = []
                        response?.errors?.domain?.map((val) => errorList?.push(val));
                        setError(errorList);
                        dispatch({ type: "SET_CUSTOMIZE_DOMAINS", payload: { ...value, connected: false } })
                        dispatch({
                            type: "SET_DOMAIN_MODAL_SHOW", payload: {
                                dotModal: false,
                                connectedModal: false,
                            }
                        });
                        var title = response?.status_message;
                        var description = null;
                        if (response?.status_message?.includes("propagated")) {
                            title = 'DNS not propagated properly';
                            description = 'Please check if you have configured your A records properly';
                        }
                        setAlert({ type: 'error', title: title, description: description });
                        // toast.error(response?.status_message)
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: "SET_DOMAIN_MODAL_SHOW", payload: {
                            dotModal: false,
                            connectedModal: false,
                        }
                    });
                    // handleClose()
                })
        } else {
            e.preventDefault()
            setError("To connect a domain, please subscribe to the Ultra plan.")
        }
    }
    const changeStatus = () => {
        showLoader();
        var endpoint = "verify-dns/";
        var method = "POST";
        if (value?.domain_status == 'verified') {
            endpoint = "deletedomain/";
            method = "DELETE";
        }
        const api = apiUrl + "/project/" + endpoint + slug;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: method,
            headers: myHeaders,
        }
        apiService(null, api, hideLoader, setOptions, "custom_message").then((response) => {
            if (response?.status_code == 200) {
                if (value?.domain_status == 'verified') {
                    setconfirmationModalShow({ show: false });
                    setisdomainConnect(false)
                    dispatch({ type: 'RESET_CUSTOMIZE_DOMAINS' })

                } else if (value?.domain_status == 'not_verified') {
                    setisdomainConnect(true)
                    dispatch({ type: "SET_CUSTOMIZE_DOMAINS", payload: { ...value, domain_status: 'pending' } })
                } else if (value?.domain_status == 'pending') {
                    setisdomainConnect(true)
                    dispatch({ type: "SET_CUSTOMIZE_DOMAINS", payload: { ...value, domain_status: 'not_verified' } })
                }
                setAlert({ type: 'success', title: response?.status_message })
            } else {
                setAlert({ type: 'error', title: response?.status_message })
            }
            hideLoader();
        }).catch((err) => {
            setAlert({ type: 'error', title: err })
        })
    }
    const handleClose = () => {
        setTimeout(() => {
            history.push(`${basePath}/page-editor/customizePageModal/customizePageDomains`)
        }, 300);
    }
    const handleDotModals = () => {
        dispatch({
            type: "SET_DOMAIN_MODAL_SHOW", payload: {
                dotModal: false,
                connectedModal: true,
            }
        });
    }
    const handleHideConnectedModal = (onDomain) => {
        dispatch({ type: "SET_DOMAIN_MODAL_SHOW", payload: { dotModal: false, connectedModal: false } });
    }
    useEffect(() => {
        if (value?.domain_status == 'verified') {
            setStatusData({ icon: checked, title: 'DNS Verified', desc: 'DNS verification is complete. You can now visit your domain.', bgColor: 'rgba(56, 234, 101, 0.29)' })
        } else if (value?.domain_status == 'pending') {
            setStatusData({ icon: pending, title: 'DNS Verification Started', desc: `DNS verification is in progress and may take a few minutes to hours. This page will update once it's complete. You can safely browse other pages while the process runs in the background.`, bgColor: 'rgba(247, 209, 12, 0.13)' })

        } else {
            setStatusData({
                icon: exclamatory, title: 'DNS Verification Not Started', desc: `Visit the DNS settings page of your DNS provider and add the DNS records listed below to the records section. 
            `, bgColor: 'rgba(12, 120, 247, 0.13)', subDesc: `Once you’ve added them, click the "Verify DNS Records" button above.`
            })

        }
    }, [value?.domain_status])

    const handleStatusClick = (disconnect = false) => {
        if (disconnect || value?.domain_status == 'verified') {
            handleDisconnect()
        } else {
            changeStatus()
        }
    }
    const handleDisconnect = () => {
        setconfirmationModalShow({
            show: true,
            icon: settings,
            title: 'Disconnect Domain',
            message: 'Are you sure that you want to disconnect your domain?',
            buttonText: 'Yes, Disconnect',
            callback: disconnectDomain
        });
    };
    const disconnectDomain = () => {
        showLoader();
        const api = apiUrl + "/project/deletedomain/" + slug;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "DELETE",
            headers: myHeaders,
        }
        apiService(null, api, hideLoader, setOptions, "custom_message").then((response) => {
            setconfirmationModalShow({ show: false, text: null, callback: null })
            if (response?.status_code == 200) {
                setAlert({ type: 'success', title: response?.status_message })
            } else {
                setAlert({ type: 'error', title: response?.status_message })
            }
            setisdomainConnect(false)
            hideLoader();
            dispatch({ type: 'RESET_CUSTOMIZE_DOMAINS' })
        }).catch((err) => {
            setAlert({ type: 'error', title: err })
        })
    }
    useEffect(() => {
        if (value?.domain && value?.connected) {
            setisdomainConnect(true)
        } else {
            setisdomainConnect(false)
        }
    }, [value?.connected])

    return (

        <section className="w-100 h-100 domain-section-container position-relative">
            <div className="hidden-scroll domain-section settings-section bg-transparent mx-auto">
                <div class="d-flex justify-content-between align-items-center gap-2 flex-wrap">
                    <span class="settings-section-heading font-weight-500 mb-0" >Domains</span>
                    {isdomainConnect && value?.domain && <DomainOptions value={value} handleStatusClick={handleStatusClick} classname="mobile" />}
                </div>
                {!isdomainConnect && <>
                    <div class="connect-domain-container">
                        <div className="title mx-auto">No Domain Found</div>
                        <button className="blue-btn mx-auto" onClick={() => setShowDomainModal(true)}>Add Domain</button>
                        <ModalErrors text={error} />
                    </div>
                </>}
                {isdomainConnect && <>{value?.domain ?
                    <div>
                        <div className="connected-domain-container">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className={`domain-status-icon ${value?.domain_status}`}>
                                    <svg width="27" height="37" viewBox="0 0 27 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.9619 16.4316H18.3613V3.38158C18.3613 0.336584 16.7119 -0.279666 14.7001 2.00408L13.2501 3.65346L0.979426 17.6097C-0.706199 19.5128 0.000676368 21.0716 2.53818 21.0716H8.1388V34.1216C8.1388 37.1666 9.78818 37.7828 11.8001 35.4991L13.2501 33.8497L25.5207 19.8935C27.2063 17.9903 26.4994 16.4316 23.9619 16.4316Z" fill="#34C759" />
                                    </svg>
                                </div>
                            </div>
                            <div class="custom-domain-input-group domain-enabled d-flex justify-content-between align-items-center border-0">
                                {value?.domain?.includes('https://') ? value?.domain : 'https://' + value?.domain}
                            </div>
                            <DomainOptions value={value} handleStatusClick={handleStatusClick} classname="desktop" />
                        </div>
                        <div className="connect-domain-container">
                            <div className="dns-sec">
                                <div className="dark-text title">DNS Setup</div>
                                <DomainStatus {...statusData} />
                            </div>
                            <div className="dns-sec">

                                <div>
                                    <span className="title dark-text">A Records</span>
                                    &nbsp;
                                    <span className="title">(Required)</span>
                                </div>
                                <div className="dns-table">
                                    <div className="dns-table-header">
                                        <div className="dns-header-cell">Type</div>
                                        <div className="dns-header-cell">Name</div>
                                        <div className="dns-header-cell">Value/Data</div>
                                        <div className="dns-header-cell">TTL</div>
                                    </div>
                                    <div className="dns-table-row">
                                        <div className="dns-table-cell">A</div>
                                        <div className="dns-table-cell">  {value?.domain?.split('.').length > 2 ? value?.domain.split('.').slice(0, -2).join('.') : '@'}</div>
                                        <div className="dns-table-cell">52.201.4.2</div>
                                        <div className="dns-table-cell">600 seconds</div>
                                    </div>
                                </div>
                            </div>
                            {!value?.domain?.includes('www') &&
                                <div className="dns-sec">

                                    <div>
                                        <span className="title dark-text">Sub-Domain Forwarding</span>
                                        &nbsp;
                                        <span className="title">(Optional)</span>
                                    </div>
                                    <div className="dns-table">
                                        <div className="dns-table-header">
                                            <div className="dns-header-cell">Protocol</div>
                                            <div className="dns-header-cell">Sub-domain</div>
                                            <div className="dns-header-cell">Destination URL</div>
                                            <div className="dns-header-cell">Forward Type</div>
                                        </div>
                                        <div className="dns-table-row">
                                            <div className="dns-table-cell">https</div>
                                            <div className="dns-table-cell">www</div>
                                            <div className="dns-table-cell">{value?.domain}</div>
                                            <div className="dns-table-cell">Permanent (301)</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    : ""
                } </>}
            </div>
            <NewConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={confirmationModalShow?.callback} />
            {showDomainModal && <DomainModal show={showDomainModal} setShow={setShowDomainModal} handleSubmit={handleSubmit} error={error} />}
            {showCustomDomainDotsModal?.dotModal && <CustomDomainDotModal show={showCustomDomainDotsModal?.dotModal} />}
            {showCustomDomainDotsModal?.connectedModal && <CustomDomainConnectedModal show={showCustomDomainDotsModal?.connectedModal} handleHide={handleHideConnectedModal} />}
            {showModalasd && <Teammoadal from={"domain-settings"} showModal={showModalasd} setShowModal={setShowModalasd} setActivePage={setActivePage} text={'Custom Domain is a Pro feature. <br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false} />}
        </section>

    )

}
export const DomainOptions = ({ classname, value, handleStatusClick }) => {
    return (
        <div className={`domain-options domain-btns-${classname}`}>
            <button type="button" onClick={() => handleStatusClick()}
                class={`customDomainModal text-nowrap ${value?.domain_status == 'verified' ? 'new-primary-btn' : (value?.domain_status == 'not_verified' ? 'blue-btn' : 'btn-gray')}`}>
                {value?.domain_status == 'verified' ? 'Disconnect Domain' : (value?.domain_status == 'not_verified' ? 'Verify DNS Records' : 'Cancel Verification')}
            </button>
            <div className="disconnect-domain-btn" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={dotsIcon} alt="" srcset="" />
            </div>
            <ul class="dropdown-menu" aria-labelledby="table-action-dropdown">
                <li>
                    <a href={"https://" + value?.domain} target="_blank" class="dropdown-item gray-hover justify-content-start gap-2">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.75 5.75L14.75 1.25M14.75 1.25H10.25M14.75 1.25L8.75 7.25M6.5 2.75H4.85C3.58988 2.75 2.95982 2.75 2.47852 2.99524C2.05516 3.21095 1.71095 3.55516 1.49524 3.97852C1.25 4.45982 1.25 5.08988 1.25 6.35V11.15C1.25 12.4101 1.25 13.0402 1.49524 13.5215C1.71095 13.9448 2.05516 14.289 2.47852 14.5048C2.95982 14.75 3.58988 14.75 4.85 14.75H9.65C10.9101 14.75 11.5402 14.75 12.0215 14.5048C12.4448 14.289 12.789 13.9448 13.0048 13.5215C13.25 13.0402 13.25 12.4101 13.25 11.15V9.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span className="me-2">Visit Domain</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.zaap.ai/learn/domains" target="_blank" class="dropdown-item gray-hover justify-content-start gap-2">
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.75 4.9375H10.25M14.75 1H1.25M14.75 9.0625H10.25M14.75 13H1.25M2.45 10H6.05C6.47004 10 6.68006 10 6.84049 9.91825C6.98161 9.84635 7.09635 9.73161 7.16825 9.59049C7.25 9.43006 7.25 9.22004 7.25 8.8V5.2C7.25 4.77996 7.25 4.56994 7.16825 4.40951C7.09635 4.26839 6.98161 4.15365 6.84049 4.08175C6.68006 4 6.47004 4 6.05 4H2.45C2.02996 4 1.81994 4 1.65951 4.08175C1.51839 4.15365 1.40365 4.26839 1.33175 4.40951C1.25 4.56994 1.25 4.77996 1.25 5.2V8.8C1.25 9.22004 1.25 9.43006 1.33175 9.59049C1.40365 9.73161 1.51839 9.84635 1.65951 9.91825C1.81994 10 2.02996 10 2.45 10Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span class="me-5">Read Guide </span>
                    </a>
                </li>
                {
                    value?.domain_status !== 'verified' &&
                    <li onClick={() => handleStatusClick(true)}>
                        <a class="dropdown-item gray-hover justify-content-start gap-2 cursor-pointer">
                            <img src={deleteIcon} width={16} height={14} alt="" srcset="" className="filter-brightness-0" />
                            <span class="me-5">Remove Domain </span>
                        </a>
                    </li>
                }
            </ul>
        </div>

    )
}
export default Domains