import React from 'react'

const DomainStatus = ({icon, bgColor, desc, title, subDesc}) => {
  return (
    <div className='dns-status-main' style={{backgroundColor:bgColor}}>
        <div className='dns-status-header'>
            <img src={icon} width={20} height={20} alt="" />
            <span>
                {title}
            </span>
        </div>
        <div className='dns-status-desc'>
            {desc}
            <span className='dns-status-sub-desc'>
              {subDesc}
            </span>
        </div>
    </div>
    
  )
}

export default DomainStatus