import "../assets/css/community.css";
import plusIcon from "../assets/img/plus.svg"
import chevron_downIcon from "../assets/img/modals/chevron-down.svg"
import chevron_down_greyIcon from "../assets/img/chevron-down-gray.svg"
import tickIcon from "../assets/img/tick.svg"
import tickCircleIcon from "../assets/img/tick-circle.svg"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useRef, useState } from "react";
import * as $ from "jquery"
import PageHeader from "../components/page-header";
import NewsletterModal from "../components/newsletter/modal";
import { Route, useHistory, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SideBar from "../components/side-bar";
import getNewsletter from "../services/getNewsletter";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../components/loader";
import setDateFormat, { newDateFormat, newTimeFormat } from "../utils/setDateFormat";
import CommunityFooter from "../components/community/footer";
import HeaderSearch from "../components/community/headerSearch";
import UpgradeModal from "../components/upgrade/upgrade-modal";
import NewsUpgradeModal from "../components/upgrade/news-upgrade-modal";
import { audiencePosts } from "../services/postRequests/audience";
import getAudience, { getDefaultAudience } from "../services/getAudience";
import dotsIcon from "../assets/img/dots.svg"
import { Modal, Button } from "react-bootstrap";
import { apiUrl, pageUrl, protocol } from "../utils/url";
import { ChevronRight } from "../utils/svg"
import { audiencePosts as newsletterPosts } from "../services/postRequests/audience"
import { PostsUpgradeModal } from "../components/newsletter/posts-upgrade-modal";
import { toast } from "react-toastify";
import NewSideBar from "../components/side-bar/new-sidebar";
import Teammoadal from "../components/teammember/team_modal";
import ConfirmationModal from "../components/confirmation-modal";
import EmptyState from "./empty-state";
import { useAlert } from "../contexts/alertContext";



let params = [];
const NewsLetter = ({ basePath, id }) => {

    const [showModalasd, setShowModalasd] = useState(false)
    let location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();
    const [loading, setloading] = useState(true);
    // const communityRef = useRef("active");
    // const communityMobileRef = useRef("active");
    const [showNewsletter, setShowNewsletter] = useState(false);
    const { slug } = useSelector(state => state?.slugReducer);
    const isPro = useSelector(state => state?.proReducer)
    const [allNewsletter, setallNewsletter] = useState([]);
    const [showUpgradeModal, setshowUpgradeModal] = useState(false);
    const [filter, setfilter] = useState({ page: 1, orderBy: null, filter: null, orderType: "desc", count: localStorage?.newsletters_count ?? 10});
    const [showNewsUpgradeModal, setshowNewsUpgradeModal] = useState(false);
    const [allAudience, setallAudience] = useState([]);
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null, deleteAll:false });
    const [isConfirmMessage, setisConfirmMessage] = useState(false)
    const { newsletterModalReducer, stripeConnectReducer } = useSelector(state => state);
    const [isEdit, setIsEdit] = useState({ is: false, id: null })
    const [selectAll, setSelectAll] = useState(false)
    const [isDuplicate, setIsDuplicate] = useState({ is: false, id: null })
    const newUser = useSelector(state => state?.newUserReducer);
    const pro = useSelector(state => state?.proReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const { setAlert } = useAlert();
    $(document).on('click', '.filter-dropdown-menu li a', function () {
        $('.filter-dropdown-menu li a').removeClass('active');
        $(this).addClass('active');
        $('.filter-dropdown-menu-selected').text($(this).attr('data-value'));
    });
    $(document).on('click', '.filter-dropdown-menu-channel li a', function () {
        $('.filter-dropdown-menu-channel li a').removeClass('active');
        $(this).addClass('active');
        $('.filter-dropdown-menu-selected-channel').text($(this).attr('data-value'));
    });
    // useEffect(() => {
    //     setShowModalasd(!isPro);
    // }, [isPro]);
    // useEffect(() => {
    //     $(communityRef.current).addClass("active");
    //     $(communityMobileRef.current).addClass("active");
    // }, []);

    useEffect(() => {
        if (location.pathname.includes("/newsletter/sendNewsletter")) {
            setShowNewsletter(true)

        }
        else if (location.pathname.includes("/newsletter")) {
            setShowNewsletter(false)
        }
    }, [location.pathname])

    useEffect(() => {
        if (!pro) {
            setShowModalasd(true);
        }else {
            setShowModalasd(false);
        params = [];
        if (slug) {
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key))
                    if (filter[key]) params.push(`${[key]}=${filter[key]}`)

                // console.log("filter1" , filter)
            };
            if (filter?.page) callNewsletter()
        }
        }
    }, [slug, filter,pro,planType]);

    const callNewsletter = () => {
        // let getRows = 12;
        // if (window.innerHeight < 1000) {
        //     getRows = 12;
        // }else if (window.innerHeight >= 1100 && window.innerHeight <= 1200) {
        //     getRows = 13;
        // }else if (window.innerHeight >= 1200 && window.innerHeight <= 1300) {
        //     getRows = 14;
        // }else if (window.innerHeight >= 1300 && window.innerHeight <= 1400) {
        //     getRows = 15;
        // }else if (window.innerHeight >= 1400 && window.innerHeight <= 1500) {
        //     getRows = 22;
        // }else if (window.innerHeight >= 1500 && window.innerHeight <= 1600) {
        //     getRows = 24;
        // }else{
        //     getRows = 27;
        // }
        // params.push(`pagination=${[getRows]}`)
        getNewsletter(slug, params?.join("&"), "community/newsletter").then((response) => {
            // console.log('response',response);
            setloading(false)
            if(response?.status_message == 'Audience Limit Reached'){
                setShowModalasd(true);
            }
            else{
                setallNewsletter(response);
            }
            hideLoader()
        }).catch((err)=>{
            
            console.log('err',err);
        })
    }

    const filterToggler = (e, value) => {
        const isDesc = e.target.classList.contains("sortable-col-desc");
    
        setfilter({ ...filter, "orderBy": value, orderType: isDesc ? "asc" : "desc" });
            
        const imgElement = e.target.querySelector('img');
        if (imgElement) {
            e.target.classList.toggle('sortable-col-desc', !isDesc);
        }
    };

    // const selectFilter = (e) => setfilter(
    //     { ...filter, filter: e.target.dataset?.value !== "All" ? e.target.dataset?.value?.toLowerCase() : null },

    //     
    // console.log("filter" , {filter})
    //     )
    const selectFilter = (e) => {
        const value = e.target.dataset?.value !== "All" ? e.target.dataset?.value?.toLowerCase() : null;
        setfilter({ ...filter, filter: value, page: 1 });

        // console.log("event",e.target.dataset?.value);
    }

    const showModal = () => {
        if (!isPro && allNewsletter?.counts?.audience > 100) {
            setshowNewsUpgradeModal(true);
            // setshowUpgradeModal(true)
            hideLoader()
            return
        }
        dispatch({
            type: "RESET_NEWSLETTER_MODAL"
        })
        setShowNewsletter(true)
        history.push(basePath + "/newsletter/sendNewsletter")
    }

    const openUpgradeModal = (e) => {
        e.preventDefault();
        setshowUpgradeModal(true)
    }
    const closeUpgradeModal = () => setshowUpgradeModal(false);
    const closeNewsUpgradeModal = () => setshowNewsUpgradeModal(false);


    const duplicateBlock = (values) => {
        // setconfirmationModalShow({
        //     show: true, text: "Are you sure you want to duplicate this block?", id: id, callBackTo: "duplicate"
        // })
        // 
        // console.log(id);
        // const api = apiUrl + "/community/newsletter/edit/" + values?.id;
        // var myHeaders = new Headers();
        // myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        // const setOptions = {
        //     method: "GET",
        //     headers: myHeaders,
        // }

        // newsletterPosts(api, hideLoader, setOptions).then((values) => {
        dispatch({
            type: "SET_NEWSLETTER_MODAL", payload: {
                ...newsletterModalReducer,
                id: values?.id, sender_name: values?.sender_name, sender_email: values?.sender_email,
                subject: values?.subject, template_json: values?.template_json, scheduled_at: values?.scheduled_at,
                editorjs_template: values?.editorjs_template_json,cover_image: values?.cover_image,
                cover_image_data_url: values?.cover_image,checked_tags: values?.tags ? JSON.parse(values?.tags) : [],
            }
        })

        // console.log("values",values)
        // }).catch((err) => 
        // console.log(err))
        setIsDuplicate({ is: true, id: values?.id })
        sessionStorage.setItem("isDuplicate", JSON.stringify(isDuplicate))

        // console.log(values);
        setShowNewsletter(true)
    }
    const copyingText = (text) => navigator.clipboard.writeText(text).then((r => setAlert({ type: 'success', title: 'Successfully Copied'})))
    const handleCloseConfirmation = () => {
        setconfirmationModalShow({ show: false, text: null, id: null })
        setisConfirmMessage(true);
    }
    const confirmedDeleteBlock = (e) => {
        // e.preventDefault();

        // showLoader()
        if (confirmationModalShow?.id) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            var requestOptions = {
            };
            var formData = new FormData();
            formData.append("from", "newsletter");
            var api = `${apiUrl}/community/newsletter/`
            if (confirmationModalShow?.callBackTo == "delete") {
                requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formData,
                    redirect: 'follow'
                }
                api = `${apiUrl}/community/newsletter/delete/${confirmationModalShow?.id}`
            }
            else {
                // api = `${apiUrl}/community/newsletter/duplicate`

                // formdata.append("project", slug)
                // formdata.append("id", confirmationModalShow?.id)

                // requestOptions = {
                //     method: "POST",
                //     headers: myHeaders,
                //     redirect: 'follow',
                //     body: formdata
                // }
            }
            // console.log(apiUrl);
            // setconfirmationModalShow({ show: false, text: null, id: null })
            fetch(api, requestOptions)
                .then(async (res) => {

                    // console.log(res);
                    const response = await res?.json();
                    if (response?.status_code == 200) {
                        setAlert({ type: 'success', title: `Broadcast Deleted Successfully` })
                        // toast.success(response?.status_message)
                        callNewsletter();
                    }
                    else {
                        setAlert({ type: 'error', title: response?.status_message })
                        // toast.error(response?.status_message)
                        hideLoader()
                    }
                    setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null  })
                    setisConfirmMessage(false)
                })
                .catch(() => {
                    setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null  })
                    setisConfirmMessage(false)
                    hideLoader()
                })
        }

    }

    const deleteAllNewsletter = () => {
        // showLoader();
        // e.preventDefault();
        const api = apiUrl + "/community/newsletter/delete-all/" + slug;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        var formData = new FormData();
        formData.append("from", "newsletter");
        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        }
        fetch(api, setOptions)
                .then(async (res) => {

                    // console.log(res);
                    const response = await res?.json();
                    if (response?.status_code == 200) {
                        setAlert({ type: 'success', title: response?.status_message })
                        // toast.success(response?.status_message)
                        callNewsletter();
                    }
                    else {
                        setAlert({ type: 'error', title: response?.status_message })
                        // toast.error(response?.status_message)
                        hideLoader()
                    }
                    setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null  })
                    setisConfirmMessage(false)
                    setSelectAll(false)
                })
                .catch(() => {
                    setconfirmationModalShow({ show: false, text: null, id: null, deleteAll: null  })
                    setisConfirmMessage(false)
                    hideLoader()
                })
    }

    const editNewsletter = async (values) => {

        const api = apiUrl + "/community/newsletter/edit/" + values?.id;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        // newsletterPosts(api, hideLoader, setOptions).then((values) => {
        dispatch({
            type: "SET_NEWSLETTER_MODAL", payload: {
                ...newsletterModalReducer,
                id: values?.id, sender_name: values?.sender_name, sender_email: values?.sender_email,
                subject: values?.subject, template_json: values?.template_json, scheduled_at: values?.scheduled_at,
                editorjs_template: values?.editorjs_template_json,
                cover_image: values?.cover_image,
                cover_image_data_url: values?.cover_image,
                channel: values?.channel
            }
        })

        // console.log("values",values)
        // }).catch((err) => 
        // console.log(err))
        setIsEdit({ is: true, id: values?.id })
        sessionStorage.setItem("isEdit", JSON.stringify(isEdit))

        // console.log(values);
        setShowNewsletter(true)

    }
    const upgradeHandleClick = (e) => {
        e.preventDefault();
        // $(".upgrade-main-btn").click();
        dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: false, open: true } });
        // setShowModalasd(false);
    }
    return (
        <>
            <div id="wrapper">
                <PageHeader />
                <div class="community-main new-sidebar">
                    {/* <SideBar from="post" /> */}
                    <NewSideBar/>

                    <section class="community-section pb-0">
                    {showModalasd && <Teammoadal showModal={showModalasd} upgradeHandleClick={upgradeHandleClick} heading={'Upgrade to access Posts.'} text={' Posts is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false}/>}

                        <div class="section-header sec-head-u">
                            <h1 class="mb-0">Broadcasts</h1>
                            <div class="d-flex full-flex">
                                <HeaderSearch filter={filter} setfilter={setfilter} name="broadcasts" />
                                <button class="btn btn-primary btn-semi-rounded import-btn align-items-center 
                                        justify-content-center newbtn-u" id="btn-newNewsletterModal" onClick={showModal}>
                                    New Broadcast
                                </button>
                            </div>
                        </div>
                        {!allNewsletter?.paginated?.data?.length > 0 && !showModalasd && (filter?.filter == 'All' || !filter?.filter) ?
                            <div class="section-main sec-main-u p-0 position-relative">
                                <EmptyState from={"newsletter"} firstText={'Your fans are waiting...'} secondText={"Start writing blog posts and get your thoughts out into the world."} buttonText={"Send Broadcast"} clickedFunction={showModal}/>
                            </div>
                        :
                        <div class="section-main sec-main-u">
                            <div class="d-flex table-responsive"  style={{overflow:"visible"}}>
                                <table class="table" border="0">
                                    <thead class="d-md-table-header-group dashview-u">
                                        <tr>
                                            <th className={`aud-leftborder thead-padd aud-text ${selectAll ? ' selected ':''} post-first-col-w`}>
                                            {allNewsletter?.paginated?.data?.length > 0 &&
                                            // <span className="checkbox me-2">
                                            //     <input type="checkbox" class="" id="checkbox-select-all" checked={selectAll}name="select-all" onChange={(e) => {setSelectAll(e.target.checked)}}/>
                                            //     <label htmlFor="checkbox-select-all" id="label"></label>
                                            // </span>
                                            <label class="custom-checkbox checkbox">
                                                <input type="checkbox" checked={selectAll} name="select-all" onChange={(e) => {setSelectAll(e.target.checked)}}/>
                                                <span class="checkmark"></span>
                                            </label>
                                            }
                                            {selectAll ?
                                                <button className="delete-all-btn" onClick={() => setconfirmationModalShow({show: true, text: "Are you sure you want to delete All broadcasts?", id: "", callBackTo: "delete", deleteAll: true})}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                                        <path d="M8.77364 0.564453C9.54452 0.564453 10.1761 1.17108 10.2319 1.94053L10.2359 2.05151V3.53857H12.4293C12.8331 3.53857 13.1605 3.87146 13.1605 4.2821C13.1605 4.6634 12.8782 4.97767 12.5146 5.02062L12.4293 5.02562H12.3789L11.8418 12.6728C11.736 14.179 10.54 15.3569 9.07236 15.4313L8.92474 15.435H4.23572C2.75088 15.435 1.51038 14.3057 1.33282 12.8223L1.31862 12.6728L0.780849 5.02562H0.731137C0.327341 5.02562 0 4.69274 0 4.2821C0 3.90079 0.282235 3.58652 0.645858 3.54357L0.731137 3.53857H2.92455V2.05151C2.92455 1.26756 3.52104 0.625299 4.27767 0.568532L4.38682 0.564453H8.77364ZM10.9122 5.02562H2.24749L2.77719 12.5669C2.82639 13.2672 3.34705 13.8256 4.00996 13.9303L4.1217 13.9435L4.23572 13.948H8.92474C9.65355 13.948 10.2646 13.4038 10.3708 12.6822L10.3833 12.5669L10.9122 5.02562ZM5.11796 7.25621C5.49291 7.25621 5.80195 7.54324 5.84418 7.91303L5.84909 7.99974V10.9739C5.84909 11.3845 5.52175 11.7174 5.11796 11.7174C4.743 11.7174 4.43396 11.4304 4.39173 11.0606L4.38682 10.9739V7.99974C4.38682 7.5891 4.71416 7.25621 5.11796 7.25621ZM8.0425 7.25621C8.41745 7.25621 8.72649 7.54324 8.76873 7.91303L8.77364 7.99974V10.9739C8.77364 11.3845 8.4463 11.7174 8.0425 11.7174C7.66755 11.7174 7.35851 11.4304 7.31627 11.0606L7.31137 10.9739V7.99974C7.31137 7.5891 7.63871 7.25621 8.0425 7.25621ZM8.77364 2.05151H4.38682V3.53857H8.77364V2.05151Z" fill="#FF2020"/>
                                                    </svg>
                                                    <span>Delete All</span>
                                                </button>
                                                :
                                                'Title'
                                            }
                                            </th>
                                            <th className={`thead-padd d-tab-none ${selectAll ? " invisible": ''}`}>
                                                <div class="filter-dropdown dropdown-toggle noselect sort-u2 " id="filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="">Status:</span>
                                                    <span class="filter-dropdown-menu-selected ms-2 font-weight-500">All</span>
                                                    <img src={chevron_downIcon} width="10px" alt=""
                                                        class="ms-1" />
                                                </div>

                                                <ul class="dropdown-menu filter-dropdown-menu" aria-labelledby="filter-dropdown">
                                                    <li>
                                                        <a class="dropdown-item active" data-value="All" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="All" onClick={selectFilter}>
                                                                <img src={tickIcon} alt="" />&nbsp;&nbsp;All</span>
                                                            <small data-value="All" onClick={selectFilter}>{allNewsletter?.counts?.all}</small>
                                                        </a>
                                                    </li>
                                                    {/* <li>
                                                        <a class="dropdown-item" data-value="Scheduled" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="All" onClick={selectFilter}>
                                                                <img src={tickIcon} alt="" data-value="All" onClick={selectFilter} />&nbsp;&nbsp;Scheduled</span>
                                                            <small class="text-muted" data-value="All" onClick={selectFilter}>{allNewsletter?.counts?.scheduled}</small>
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <a class="dropdown-item" data-value="Sent" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="All" onClick={selectFilter}>
                                                                <img src={tickIcon} alt="" data-value="All" onClick={selectFilter} />&nbsp;&nbsp;Sent</span>
                                                            <small data-value="All" onClick={selectFilter}>{allNewsletter?.counts?.sent}</small>
                                                        </a>
                                                    </li>
                                                </ul> 
                                            </th>
                                            <th class={`thead-padd resp d-none-u ${selectAll ? " invisible": ''}`} onClick={(e) => filterToggler(e, "date_sent")}>
                                                <span className="sort-u">
                                                    Date &nbsp;&nbsp;
                                                    <img src={chevron_down_greyIcon} alt="" />
                                                </span>
                                            </th>
                                            <th class={`thead-padd d-tab-none resp d-none-u ${selectAll ? " invisible": ''}`} onClick={(e) => filterToggler(e, "date_sent")}>
                                                <span className="sort-u">
                                                    Time&nbsp;&nbsp;
                                                    <img src={chevron_down_greyIcon} alt="" />
                                                </span>
                                            </th>
                                            <th class={`thead-padd resp d-none-u ${selectAll ? " invisible": ''}`} onClick={(e) => filterToggler(e, "sends")}><span className="sort-u">Sends&nbsp;&nbsp;<img
                                                src={chevron_down_greyIcon} alt="" /></span></th>
                                            <th class={`thead-padd resp d-none-u ${selectAll ? " invisible": ''}`} onClick={(e) => filterToggler(e, "opens")}><span className="sort-u">Opens&nbsp;&nbsp;<img
                                                src={chevron_down_greyIcon} alt="" /></span></th>
                                            <th className={`th-end-u ${selectAll ? " invisible": ''}`}></th> 
                                        </tr>
                                    </thead>
                                    {
                                        loading ?
                                    <tbody>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td class="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                /></td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td class="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                /></td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td class="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                /></td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td class="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                /></td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td class="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                /></td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td class="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                /></td>

                                        </tr>

                                        <tr>
                                            <td className="d-md-table-cell">


                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start d-none">
                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">


                                                        <Skeleton height={40}
                                                            className="d-md-none"
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />

                                                        <span class="d-md-none d-flex text-lightgray">
                                                            <span class="me-4">21 Sep</span>
                                                            <span class="me-4">1.2k Sends</span>
                                                            <span>556 Opens</span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </td>
                                            <td class="d-md-table-cell d-none">

                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                />
                                            </td>
                                            <td class="d-md-table-cell d-none">
                                                <Skeleton height={40}
                                                    borderRadius="10px"
                                                    highlightColor="#ffffff"
                                                    duration={4}
                                                /></td>

                                        </tr>

                                    </tbody>
                                    :
                                    <tbody>
                                                {
                                                    allNewsletter?.paginated?.data?.length > 0 && (
                                                        allNewsletter?.paginated?.data?.map((val, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <p class="mb-0 d-inline-flex align-items-center justify-content-start">
                                                                        <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                                            <span>{val?.subject}</span>
                                                                            {/* <span class="d-md-none d-flex text-lightgray">
                                                                                <span class="me-4">{setDateFormat(val?.scheduled_at, true)}</span>
                                                                                <span class="me-4">{val?.sent} Sends</span>
                                                                                <span>{val?.opens} Opens</span>
                                                                            </span> */}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td class="d-md-table-cell d-none">
                                                                    <span class={`d-flex align-items-center status-u  ${val?.status == 'Sent' ? 'Published': val?.status == 'Scheduled' ? 'Scheduled':'pending'}`}>
                                                                         {/* {val?.channel == 'post' ? (
                                                                            <><img src={tickCircleIcon} width="20px" alt="" />
                                                                            <span class="ms-2">{val?.status == 'Sent' ? 'Published':'Scheduled'}</span></>
                                                                        ) :  
                                                                         (
                                                                            <>  */}
                                                                                <div className="dot-u"></div>
                                                                                {val?.status}
                                                                            {/* </>
                                                                        )
                                                                        } */}
                                                                    </span>
                                                                </td>
                                                                <td class="d-md-table-cell d-none">{newDateFormat(val?.scheduled_at)}</td>
                                                                <td class="d-md-table-cell d-tab-none d-none">
                                                                {newTimeFormat(val?.scheduled_at)}
                                                                </td>
                                                                
                                                                <td class="d-md-table-cell d-none">
                                                                {val?.channel == 'post' ? (
                                                                            <>—</>
                                                                        ) : 
                                                                        (
                                                                            val?.sent
                                                                        )
                                                                        }</td>
                                                                <td class="d-md-table-cell d-none">
                                                                {val?.channel == 'post' ? (
                                                                            <>—</>
                                                                        ) : 
                                                                        (
                                                                            val?.opens
                                                                        )
                                                                        }
                                                                </td>
                                                                <td>
                                                                    <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end dropdown-subscribers" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                        <img src={dotsIcon} width="" alt="" />
                                                                    </div>
                                                                    <ul class="dropdown-menu" aria-labelledby="table-action-dropdown">
                                                                        <li><a class="dropdown-item" onClick={() => duplicateBlock(val)}><span class="me-5">Duplicate </span><ChevronRight /></a></li>
                                                                        <li><a class="dropdown-item theme-active semi-bold" onClick={() =>
                                                                        setconfirmationModalShow({
                                                                            show: true, text: "Are you sure you want to delete this post?", id: val?.id, callBackTo: "delete"
                                                                        })} ><span class="me-5">Delete Post</span><ChevronRight /></a></li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                            </tbody>
                                    }
                                </table>
                            </div>


                            <div class="section-footer d-flex align-items-center justify-content-between">
                                <CommunityFooter from={"newsletters"} loading={loading} filter={filter} setfilter={setfilter} total={allNewsletter?.paginated?.total} last_page={allNewsletter?.paginated?.last_page} current_page={allNewsletter?.paginated?.current_page}/>
                            </div>

                        </div>
                        }
                        {/* {showModalasd && <Teammoadal showModal={showModalasd} upgradeHandleClick={upgradeHandleClick} heading={'Upgrade to access Posts.'} text={' Posts is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.'}/>} */}
                    </section>

                </div>

            </div>
            {showNewsletter && <Route path={basePath + "/newsletter/:addNewsLetter"} children={() => <NewsletterModal from={"newsletter"} basePath={basePath} show={showNewsletter}
                setShow={setShowNewsletter} id={id} callNewsletter={callNewsletter} edit={isEdit} setIsEdit={setIsEdit} duplicate={isDuplicate} setIsDuplicate={setIsDuplicate} />}
            />}

            {showUpgradeModal && <UpgradeModal isOpen={showUpgradeModal} onHide={closeUpgradeModal} />}
            {showNewsUpgradeModal && <NewsUpgradeModal isOpen={showNewsUpgradeModal} onHide={closeNewsUpgradeModal} />}
            {confirmationModalShow?.show &&
                // <Modal show={confirmationModalShow?.show} onHide={handleCloseConfirmation}>
                //     <Modal.Header closeButton>
                //         <Modal.Title>Confirmation Message</Modal.Title>
                //     </Modal.Header>
                //     <Modal.Body>{confirmationModalShow?.text}</Modal.Body>
                //     <form onSubmit={confirmedDeleteBlock}>
                //         <Modal.Footer>
                //             <Button variant="secondary" onClick={handleCloseConfirmation}>
                //                 No
                //             </Button>
                //             <Button variant="primary" type="submit" autoFocus>
                //                 Yes
                //             </Button>
                //         </Modal.Footer>
                //     </form>
                // </Modal>
                <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} setisConfirmMessage={setisConfirmMessage} callback={confirmationModalShow?.deleteAll ? deleteAllNewsletter : confirmedDeleteBlock} />
            }
        </>
    )
}
export default NewsLetter