import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap"
import plus from "../../assets/img/blue-filled-plus.svg";
import returnIcon from "../../assets/img/modals/return.svg";
import ErrorMessage from '../errors/errorMessage';
const DomainModal = ({ show, setShow, handleSubmit, error }) => {
    const [value, setValue] = useState({
        domain: null, tab: "domains",
    })
    const handleClose = () => {
        setShow(false);
    };
    const handleChange = (e) => {
        setValue({...value, [e.target.name]: e?.target?.value?.toLowerCase() })
    };
    const submit = (e) => {
        const updatedValue = { ...value, domain: 'https://' + value?.domain };
        handleSubmit(e, updatedValue);
    }
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header className="borderless px-3 pb-0">
                <Modal.Title className="font-weight-500 fs-22px d-flex align-items-center gap-2">
                    <img width={30} src={plus} alt="" srcset="" />
                    Add Domain
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={(e) => submit(e)} className='px-3'>
                <Modal.Body className="py-0 font-weight-500 fs-14px mt-20px px-0">
                    <ErrorMessage error={error} />
                    <div className='zaapGlobal-input d-inline-flex align-items-center'>
                        <div className='text-secondary'>https://</div>
                        <input type="text" class={`bg-transparent fs-12px p-0 text-dark`} name="domain" value={value?.domain ?? ''} onChange={handleChange} required="required" placeholder="domain.com" />
                    </div>
                </Modal.Body>
                <Modal.Footer className="borderless justify-content-start gap-3 mt-20px pt-0 px-0 pb-3">
                    <button className="close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center fs-12px font-weight-500 m-0" type='button' onClick={handleClose}>
                        <span>Cancel</span>
                        <small class="badge bg-light text-dark p-1 ms-2">Esc</small>
                    </button>
                    <button className="new-primary-btn m-0" type="submit">
                        Add Domain
                        <small class="primary-badge">
                            <img src={returnIcon} alt="" />
                        </small>
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default DomainModal