const INIT_STATE = {
    domain: null, tab: "domains",
    project_id: null,
    domain_status: 'not_verified',
}
const customizeDomainsReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_CUSTOMIZE_DOMAINS':
            return action.payload 
        case 'RESET_CUSTOMIZE_DOMAINS':
            return INIT_STATE
        default:
            return state
    }
}
export default customizeDomainsReducer