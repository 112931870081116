import returnIcon from "../../assets/img/modals/return.svg"
import imageIcon from "../../assets/img/image-icon.svg";
import emailIcon from "../../assets/img/newsletter/newsletter.svg"
import contentIcon from "../../assets/img/newsletter/content.svg"
import detailsIcon from "../../assets/img/newsletter/details.svg"
import analyticsIcon from "../../assets/img/newsletter/analytics.svg"
import analyticsMainIcon from "../../assets/img/newsletter/analytics-main.svg";
import trashIcon from "../../assets/img/trash.svg";
// import removeicon from "../../assets/img/products/removeicon.svg";
import * as $ from "jquery";
import { useHistory, useParams } from "react-router-dom";
import Loader, { hideLoader, showLoader } from "../loader";
import { Modal, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../utils/url";
import { toast } from "react-toastify";
import ErrorMessage from "../errors/errorMessage";
import { useState, useEffect, useRef } from "react";
import scheduleIcon from "../../assets/img/newsletter/schedule.svg";
import quesMarkIcon from "../../assets/img/question-mark.svg";
import "../../assets/calendar-plugin/js/mobiscroll.jquery.min.js"
import chevron_down from "../../assets/img/modals/chevron-down.svg"
import { months } from "../../utils/analytics/line";
import EmailTestModal from "./test-email-modal.jsx";
import EditContentModal from "./edit-content-modal.jsx";
import EditorJSModal from "../editorjs/EditorJsModal"
import CroppieModal from "../croppie-modal";
import Croppie from "croppie";
import { PostsUpgradeModal } from "./posts-upgrade-modal";
import audienceIcon from "../../assets/img/sidebar-audience-icon.svg"
import { useAlert } from "../../contexts/alertContext.js";


var today = new Date();
var today_text = today.getDate() + ' ' + months[today.getMonth()] + ' ' + today.getFullYear();

var now = new Date();
var current_time = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

$.noConflict();

export var emailTemplateMarkup = [];
export var editorJSTemplateMarkup = [];
const NewsletterModal = ({ from, show, setShow, callNewsletter, edit, setIsEdit, duplicate, setIsDuplicate, }) => {
    let history = useHistory();
    const newsletterModalReducer = useSelector(state => state.newsletterModalReducer);
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [error, setError] = useState([])
    const dispatch = useDispatch();
    let ejInstance = useRef();
    const [showEmailTestModal, setshowEmailTestModal] = useState(false);
    const [croppie_image, setCroppie_image] = useState({ url: "", id: null });
    const [cover_image, setCover_Image] = useState([]);
    const [showCroppieModal, setShowCroppieModal] = useState(null);
    const [deleted_cover_image, setDeleted_cover_image] = useState([])
    const [croppie, setCroppie] = useState(null);
    const [showContentModal, setshowContentModal] = useState(false);
    const [isEdit, setisEdit] = useState([]);
    const [showModalasd, setShowModalasd] = useState(false)
    const isPro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const [tags, setTags] = useState([]);
    const [checkedTags, setCheckedTags] = useState([]);
    const audienceTags = useSelector(state => state?.audienceTagsReducer);
    const { setAlert } = useAlert();
    let editid = edit?.id;
    const removeModal = () => {
        setShow(false)
        sessionStorage.removeItem("isEdit")
        setIsEdit({ is: false, id: null })
        sessionStorage.removeItem("isDuplicate")
        setIsDuplicate({ is: false, id: null })
        history.push(basePath + "/" + from)
    };
    function sendNewsletter(apiName) {

        // console.log("API Name", apiName)
        showLoader();
        setError([])
        var form_data = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

        for (let index = 0; index < $('.email-body-image-file').length; index++) {
            form_data.append('images[]', $('.email-body-image-file')[index].files[0]);
        }

        for (const key in newsletterModalReducer) {
            if (Object.hasOwnProperty.call(newsletterModalReducer, key)) {
                if(newsletterModalReducer[key] != null){
                    if(key == 'checked_tags'){
                        newsletterModalReducer?.checked_tags.map((val) => {
                            form_data.append("checked_tags[]", val)    
                        });
                    }else {
                        form_data.append(key, newsletterModalReducer[key]);
                    }
                }
            }
        };
        emailTemplateMarkup = newsletterModalReducer?.template_json;
        editorJSTemplateMarkup = newsletterModalReducer?.editorjs_template;
        form_data.append('project', slug);
        form_data.append('template', emailTemplateMarkup);
        form_data.append('editojs_template', editorJSTemplateMarkup);
        form_data.append('selectedDate', today_text);
        form_data.append('selectedTime', current_time);
        if (!form_data.get('cover_image') ) {
            form_data.delete('cover_image');
        }
        if (apiName == "update")
            form_data.append('newsletter', edit?.id);
        $.ajax({
            type: 'POST',
            url: apiUrl + "/community/newsletter/" + apiName,
            dataType: 'text',  // <-- what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            headers: {
                'Authorization': `Bearer ${localStorage?.getItem("token")}`
            },
            data: form_data,
            success: (data) => {
                data = JSON.parse(data);
                if (data.status_code !== 200) {
                    // toast.error(data.status_message)
                    setAlert({ type: 'error', title: data?.status_message })
                    hideLoader();
                }
                else if (data.status_code == 200) {
                    setAlert({ type: 'success', title: data?.status_message })
                    // toast.success(data.status_message)
                    callNewsletter()
                    removeModal();
                    hideLoader();
                }
            },
            error: (data) => {
                const errors = Object.values(JSON.parse(data.responseText)?.errors);
                setError(errors);
                if (errors?.length > 0) {
                    errors.map((error) => setAlert({ type: 'error', title: error[0]}))
                }
                else {
                    setAlert({ type: 'error', title: JSON.parse(data.responseText)?.status_message})
                    // toast.error(JSON.parse(data.responseText)?.status_message)
                }
                $(".modal-body").scrollTop(0);
                hideLoader();

            }
        });
    }
    const [loading, setloading] = useState(true);
    const handleSubmit = () => {
        let $break = false;
        // if (!isPro && newUser == 1) {
        //     setShowModalasd(true);
        //     return false;
        // }
        // emailTemplateMarkup = [];
        emailTemplateMarkup = newsletterModalReducer?.template_json;
        editorJSTemplateMarkup = newsletterModalReducer?.editorjs_template;
        if ($("#sender_from_name").val() == '') {
            toast.error("Please Enter Sender From Name")
            return false;
        }
        if ($("#sender_test_email").val() == '') {
            toast.error("Please Enter Test Email")
            return false;
        }
        // const scheduledTime = new Date(selectedDate + 'T' + selectedTime + ':00Z');
        $('.email-body-h1-tag').each((key, value) => {
            if (value.value == '') {
                toast.error("Please enter something in heading")
                $break = true;
            }
        });
        $('.email-body-h2-tag').each((key, value) => {
            if (value.value == '') {
                toast.error("Please enter something in subheading")
                $break = true;
            }
        });
        $('.email-body-image-file').each((key, value) => {
            if (value.value == '') {
                toast.error("Please upload image")
                $break = true;
            }
        });
        $('.email-body-p-tag').each((key, value) => {
            if (value.value == '') {
                toast.error("Please enter something in text")
                $break = true;
            }
        });
        $('.email-body-button-tag').each((key, value) => {
            if (value.value == '') {
                toast.error("Please enter something in the button")
                $break = true;
            }
        });
        $('.email-body-button-link-tag').each((key, value) => {
            if (value.value == '') {
                toast.error("Please enter button link")
                $break = true;
            }
        });
        // console.log('emailTemplateMarkup',emailTemplateMarkup);
        if (emailTemplateMarkup == [] || emailTemplateMarkup == null) {
            toast.error("Posts Content is Required")
            $break = true;
        }
        if ($break) {
            return false;
        }
        hideLoader();
        setshowEmailTestModal(false)
        const apiName = edit?.is == true ? "update" : "create"
        sessionStorage.removeItem("isEdit")
        sendNewsletter(apiName)

    };
    const upgradeHandleClick = (e) => {
        e.preventDefault();
        // $(".upgrade-main-btn").click();
        if (newUser == 1) {
            history.push(basePath + '/settings/upgrade-zaap')
        }else{
        dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: false, open: true } });
        }
        setShowModalasd(false)
        // setShowModalasd(false);
    }

    const openEmailTestModal = () => {
        setshowEmailTestModal(true)
    };
    const closeEmailTestModal = () => {
        setshowEmailTestModal(false)
    };


    const openContentModal = () => {
        setshowContentModal(true)
    };
    const closeContentModal = () => {
        setshowContentModal(false)
    };

    $(document).ready(function () {
        $(document).on('click', '.delete-email-body-block', function () {
            if ($(this).attr('data-id') == 'button') {
                $(this).parent().parent().remove();
            }
            else {
                $(this).parent().remove();
            }
        })

        $(".email-blocks-block-template-field").unbind().click(function () {
            let selected_block = $(this).attr('data-id') + '_tag';
            $(".email-body-template-field").append(eval(selected_block));

        });

        $(".add-block-template-field").unbind().click(function () {
            $(".email-blocks-main-template-field").slideToggle(50);
        });

        $(".send-test-email-btn").unbind().click(function () {
            if ($("#sender_from_name").val() == '') {
                // growl({
                //     message: 'Please Enter Sender From Name',
                //     type: "error",
                //     overwrite: true,
                //     duration: 2000
                // });
                return false;
            }
            if ($("#sender_test_email").val() == '') {
                // growl({
                //     message: 'Please Enter Test Email',
                //     type: "error",
                //     overwrite: true,
                //     duration: 2000
                // });
                return false;
            }
            sendNewsletter(true);
        });

    })

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'checked_tags') {
            const updatedTags = checked
                ? [...newsletterModalReducer?.checked_tags, value]
                : newsletterModalReducer?.checked_tags.filter(tag => tag !== value);
            dispatch({
                type: "SET_NEWSLETTER_MODAL",
                payload: { ...newsletterModalReducer, [name]: updatedTags }
            });
        } else if (name !== "cover_image") {
            dispatch({
                type: "SET_NEWSLETTER_MODAL",
                payload: { ...newsletterModalReducer, [name]: value }
            });
        }
        else {
            setCroppie_image({ url: "", id: null, name: null, type: null });
            generate_image_preview(e.target);
            hideLoader();
        }
        if (newsletterModalReducer?.id != edit?.id) {
            // if (newsletterModalReducer?.test_email) {
            //   newsletterModalReducerTestemail = newsletterModalReducer?.test_email;
            //   newsletterModalReducerTestemail = "";
            // }
        }
    }


    // 
    // console.log(newsletterModalReducer)
    const renderCalendar = () => {
        var myCalendar = $('#calendar-picker').mobiscroll().datepicker({
            controls: ['calendar'],
            display: 'inline',
            calendarSize: 1,
            selectMultiple: false,
            selectMin: 1,
            selectMax: 1,
            returnFormat: 'locale',
            themeVariant: 'light',
            theme: 'ios',
            // min:  new Date,
            onChange: function (e) {
                if (e.value) {
                    today = new Date(e.value);
                    today_text = today.getDate() + ' ' + months[today.getMonth()] + ' ' + today.getFullYear();
                    $(".date-filter-dropdown-menu-selected").text(today_text);
                    // dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...newsletterModalReducer, ['send_date']: today_text } })
                }
            }
        }).mobiscroll('getInst');

        // 
        // console.log("myCalendars", myCalendar);
        setTimeout(() => {
            myCalendar?.setVal([today]);
        }, 1500);
    }
    useEffect(() => {
        if ($('#calendar-picker').length > 0) {
            $('.date-filter-dropdown-menu').on('click', function (e) {
                e.stopPropagation();
            });
            $(".date-filter-dropdown-menu-selected").text(today_text);

            renderCalendar();

        }
    }, [$('#calendar-picker')])

    let TEMPLATE_EDIT = {
        time: new Date().getTime(),
        blocks: null
    };


    useEffect(() => {

        if (edit?.is == true || duplicate?.is == true) {
            // if (edit?.is == true){
            if (newsletterModalReducer?.id === edit?.id || newsletterModalReducer?.id === duplicate?.id) {

                let template_data = newsletterModalReducer?.template_json;
                let editorjs_template_data = newsletterModalReducer?.editorjs_template;
                let blocks = [];

                // console.log('editorjs_template_data', editorjs_template_data);
                if (editorjs_template_data) {
                    editorjs_template_data = JSON.parse(editorjs_template_data);
                    TEMPLATE_EDIT = editorjs_template_data;
                    emailTemplateMarkup = newsletterModalReducer?.template_json ? JSON.parse(newsletterModalReducer?.template_json) : null;
                    editorJSTemplateMarkup = editorjs_template_data;
                }
            }

        }
    }, [edit?.is]);
    const renderTime = () => {
        const myTimePicker = $('#time-picker').mobiscroll().datepicker({
            controls: ['timepicker'],
            display: 'inline',
            calendarSize: 1,
            selectMultiple: false,
            selectMin: 1,
            selectMax: 1,
            returnFormat: 'yyyy-mm-dd', // Change to valid date format
            themeVariant: 'light',
            theme: 'ios',
            onChange: function (e) {
                if (e.value) {

                    // console.log(e);
                    now = new Date(e.value);
                    current_time = now.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

                    // console.log("current_time", current_time);
                    $(".date-filter-dropdown-menu-selected-time").text(current_time);
                }
            }

        }).mobiscroll('getInst');
        //   dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...newsletterModalReducer, ['send_time']: current_time } })

        setTimeout(() => {
            myTimePicker?.setVal([new Date(), new Date()]); // Set default time to current time
        }, 1500);
    };

    useEffect(() => {
        if ($('#time-picker').length > 0) {
            $('.date-filter-dropdown-menu-time').on('click', function (e) {
                e.stopPropagation();
            });
            $(".date-filter-dropdown-menu-selected-time").text(current_time);
            renderTime();
        }
    }, [$('#time-picker')])

    let defaultCheck = false;
    if (newsletterModalReducer?.id === edit?.id || newsletterModalReducer?.id === duplicate?.id && newsletterModalReducer?.scheduleTime == "schedule") {
        defaultCheck = true;

        // console.log('defaultCheck1', defaultCheck);
    }
    else if (newsletterModalReducer?.id === edit?.id || newsletterModalReducer?.id === duplicate?.id && newsletterModalReducer?.scheduleTime == "sendnow") {
        defaultCheck = true;

        // console.log('defaultCheck5', defaultCheck);
    }
    else if (newsletterModalReducer?.id !== edit?.id || newsletterModalReducer?.id !== duplicate?.id && newsletterModalReducer?.scheduleTime == "schedule") {
        defaultCheck = true;

        // console.log('defaultCheck2', defaultCheck);
    }
    else if (newsletterModalReducer?.id !== edit?.id || newsletterModalReducer?.id !== duplicate?.id && newsletterModalReducer?.scheduleTime == "sendnow") {
        defaultCheck = true;

        // console.log('defaultCheck3', defaultCheck);
    }
    //   if(newsletterModalReducer?.id === edit?.id){
    const date = new Date(newsletterModalReducer?.scheduled_at);

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDateString = date.toLocaleString('en-US', options);

    const [day, month, year] = formattedDateString.split(" ");
    const finalDateString = `${day} ${month} ${year}`;

    const time = new Date(newsletterModalReducer?.scheduled_at);
    const timeString = time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });

    // console.log(timeString);
    //   } 

    function generate_image_preview(input) {
        showLoader();
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            if (input.files[0].size >= 5242880) {
                $("#image_file_input").val("");
                let notification_container = $(".notification-container");
                toast.error("File Size Should not exceed 5MBs");
                return;
            }
            let val = input.files[0].name;
            let valid_extensions = ["png", "jpg", "jpeg"];
            let extension =
                val.substring(val.lastIndexOf(".") + 1, val.length) || val;
            if (!valid_extensions.includes(extension)) {
                $("#image_file_input").val("");
                let notification_container = $(".notification-container");
                toast.error("Invalid Image File. Should be png, jpg or jpeg");
                return;
            }
            reader.onload = (event) => {
                setCroppie_image({
                    ...croppie_image,
                    url: event.target.result,
                });
                setShowCroppieModal(true);
                setTimeout(() => {
                    const el = document.getElementById("image_demo2");
                    try {
                        // console.log("el", el);
                        if (el) {
                            var croppieInstance = new Croppie(el, {
                                enableExif: true,
                                viewport: {
                                    width: 850,
                                    height: 470,
                                    type: "square",
                                },
                                boundary: {
                                    width:870,
                                    height: 570,
                                },
                            });
                            croppieInstance.bind({
                                url: event.target.result,
                            })
                                .then(function () {
                                    setTimeout(() => { }, 300);
                                })
                                .catch(() => {
                                    croppieClose();
                                });
                            console.log("Croppie Instance", croppieInstance)
                            setCroppie(croppieInstance);
                        }
                    } catch { }
                }, 500);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    const croppieClose = () => {
        showLoader();
        setShowCroppieModal(false);
        setCroppie(null);
        setTimeout(() => {
            hideLoader();
        }, 400);
    };
    const croppedImage = () => {
        if (croppie !== null) {
            croppie
                .result({
                    type: "canvas",
                    size: "viewport",
                    format: "png",
                    quality: 1,
                })
                .then((response) => {
                    // const tempObj = { image_url: response };
                    croppie
                        .result({
                            size: "viewport",
                            format: "png",
                            quality: 1,
                            type: "blob",
                        })
                        .then((res) => {
                            dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...newsletterModalReducer, "cover_image": res, "cover_image_data_url": response} })

                            croppieClose()
                            // console.log("Cover Image", cover_image)
                        });
                });
        }
    };

    const deleteImage = (image_name) => {
        if (image_name !== "cover_image") {
            let temp_obj = { ...newsletterModalReducer };
            delete temp_obj["content_file_name"];
            dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...temp_obj, [image_name]: null } })
        }
        else {
            let temp_obj = { ...newsletterModalReducer };
            // delete temp_obj["cover_image"];
            // delete temp_obj["cover_image_data_url"];
            dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...newsletterModalReducer, ["cover_image"]:null,["cover_image_data_url"]: null } })
        }
    };
    // function Removecardfun({ i, id }) {
    //     setDeleted_cover_image([...deleted_cover_image, id])
    //     const covimg = [...cover_image]
    //     covimg.splice(i, 1)
    //     setCover_Image(covimg)
    // }
    const handleHideModal = () => {
        setShowCroppieModal(false);
        setCroppie(null);
        setCroppie_image({ url: "", id: null });
        // history.push(basePath + "/products");
    };
    const handleopenmodal = (e) => {
        setCroppie_image({ url: "", id: null, name: null, type: null });
        // console.log("croppie_image", croppie_image);
        generate_image_preview(e.target);
        hideLoader();
    };
    useEffect(()=>{
        dispatch({ type: "SET_NEWSLETTER_MODAL", payload: { ...newsletterModalReducer, ["channel"]:from } });
    },[from]);
    return (
        <>
            <Modal id="newNewsletterModal" show={show} onHide={removeModal} placement="end" style={{ width: "auto", margin: 0 }} className="right marketing-section-modal" >
                <div class="modal-dialog modal-dialog-centered" role="document" style={{ height: "100%", margin: 0 }}>
                    <div class="modal-content p-0 zaap-border-straight" style={{ height: "100%" }}>

                        <div class="modal-header send-newsletter-header" style={{ border: "none", }}>
                            <h4 class="modal-title create-biolink-heading posthead">{edit?.is ? `Edit` : `Create`} {from == "newsletter"?"Broadcasts":"Posts"}</h4>


                            <button type="button" class="close-new" onClick={removeModal}>
                                <span aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
                                    <path d="M8 1.5L1 8.5M1 1.5L8 8.5" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>Exit
                                </span>   
                            </button>
                        </div>

                        <div class="modal-body body-u">

                            <ErrorMessage error={error} />

                            <div class="accordion-item borderless mt-20px">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button btn borderless d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#sendNewsletterDetails" aria-expanded="false" aria-controls="sendNewsletterDetails">
                                        <img src={detailsIcon} width="16px" height="14px" alt="" />
                                        <h5 class="mb-0 ms-2 font-weight-500">Details</h5>
                                    </button>
                                </h2>
                                <div id="sendNewsletterDetails" class="accordion-collapse collapse show" aria-labelledby="headingOne">

                                    <div class="form-group mt-3">
                                        <label class="text-gray font-weight-500"><small>{newsletterModalReducer.channel == "post" ?'Post Title':'Subject'}</small></label>
                                        <input type="text" class="form-control zaap-form-control new-title-inp" name="subject" required="required" value={newsletterModalReducer?.id === edit?.id || newsletterModalReducer?.id === duplicate?.id ? newsletterModalReducer?.subject : null} onChange={handleChange} placeholder={`${newsletterModalReducer.channel == "post" ?'Give a descriptive title':'My Awesome Newsletter'}`} />
                                    </div>
                                    {newsletterModalReducer.channel != "post" ?
                                    <div class="form-group mt-3">
                                        <label class="text-gray font-weight-500"><small>Sender</small></label>
                                        <div className="row">
                                            <div className="col-6">
                                                <input type="text" class="form-control zaap-form-control new-title-inp" name="sender_name" required="required" value={newsletterModalReducer?.id === edit?.id || newsletterModalReducer?.id === duplicate?.id ? newsletterModalReducer?.sender_name : null} onChange={handleChange} placeholder="Micheal Scott" />
                                            </div>
                                            <div className="col-6 ps-0">
                                                <input type="text" class="form-control zaap-form-control new-title-inp" name="sender_email" required="required" value={newsletterModalReducer?.id === edit?.id || newsletterModalReducer?.id === duplicate?.id ? newsletterModalReducer?.sender_email : null} onChange={handleChange} placeholder="micheal@dundermifflin.com" />

                                            </div>

                                        </div>
                                        <div className="mt-2 mb-2">
                                            <small><img src={quesMarkIcon} alt="" srcset="" className="mb-1" /> <span>Replies will be sent to the email you provide.</span></small>

                                        </div>
                                    </div>:''
                                    }
                                    {/* <div class="form-group mt-4 mb-4">
                                        <label for="exampleInputEmail1" class="text-gray font-weight-500"><small>Channels</small></label>
                                        <div class="up-in-toggle bg-f1f1f1 w-75 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                            <input type="radio" id="text_align_left1" name="channel" defaultChecked={newsletterModalReducer?.channel == "both" ? defaultCheck : false} style={{ display: "none" }} onChange={handleChange} value={"both"} />
                                            <label for="text_align_left1" class="btn-semi-rounded newsletter-btn-semi-rounded channels-toggle">Both</label>
                                            <input type="radio" id="text_align_center" name="channel" defaultChecked={newsletterModalReducer?.channel == "newsletter" ? defaultCheck : false} style={{ display: "none" }} value={"newsletter"} onChange={handleChange} />
                                            <label for="text_align_center" class="btn-semi-rounded newsletter-btn-semi-rounded channels-toggle"> Send email</label>
                                            <input type="radio" id="text_align_right1" name="channel" defaultChecked={newsletterModalReducer?.channel == "post" ? defaultCheck : false} style={{ display: "none" }} value={"post"} onChange={handleChange} />
                                            <label for="text_align_right1" class="btn-semi-rounded newsletter-btn-semi-rounded channels-toggle"> Post to profile</label>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                            <div class="accordion-item borderless mt-20px" >
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button btn borderless d-flex align-items-center collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sendNewsletterContent" aria-expanded="false" aria-controls="sendNewsletterContent">
                                        <img src={contentIcon} width="16px" height="14px" alt="" />
                                        <h5 class="mb-0 ms-2 font-weight-500">Content</h5>
                                    </button>
                                </h2>
                                <div id="sendNewsletterContent" class="accordion-collapse collapse mt-0"
                                    aria-labelledby="headingOne">

                                    {/* <div className="form-group mt-3 mb-4">
                                        <label className="text-gray font-weight-500">
                                            <small>Upload image</small>
                                        </label>
                                        <div className="preview-zone hidden "></div>

                                        <div
                                            style={{
                                                width: "100%",
                                                height: 140,
                                                marginTop: 5,
                                            }}
                                            className="dropzone-wrapper dropzone-wrapper4 product-dropzone mb-2"
                                        >
                                            <>
                                                <div className="dropzone-desc">
                                                    <img src={imageIcon} alt="" />
                                                    <h6 className="mt-2 mb-1 text-gray">
                                                        Drag and drop files, or{" "}
                                                        <a className="text-primary decoration-none">
                                                            click here to browse
                                                        </a>
                                                    </h6>
                                                    <small className="text-muted-light">
                                                        5MB max file size upload
                                                    </small>
                                                </div>
                                                <input
                                                    type="file"
                                                    className="dropzone dropzone4"
                                                    name="cover_image"
                                                    accept=".png,.jpg,.jpeg"

                                                    id="image_file_input"
                                                    onChange={handleopenmodal}
                                                />
                                            </>
                                        </div>
                                    </div>
                                    <div >
                                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "start" }}>
                                            {cover_image?.map((e, i) => {
                                                return <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}><div key={i}> <img src={e.image_url ? e.image_url : e.images} width={"170px"} style={{ marginLeft: 4, borderRadius: 13, marginTop: 5 }} />
                                                </div>
                                                    <div>
                                                        <img onClick={() => Removecardfun({ i, id: e.id })} src={trashIcon} width={"22px"} style={{ padding: 4 }} />

                                                    </div>
                                                </div>;
                                            })}
                                        </div>
                                    </div> */}

                                    <div className="form-group" style={{paddingTop : "20px"}}>
                                        <label className="text-gray font-weight-500 mb-2"><small>Upload Cover</small></label>
                                        <div className="preview-zone hidden"></div>

                                        {
                                            newsletterModalReducer?.cover_image_data_url ?
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <img src={newsletterModalReducer?.cover_image_data_url} alt="" width="250px" style={{ borderRadius: "30px" }} />
                                                    <div onClick={() => deleteImage("cover_image")} className="ms-3 me-3 cursor-pointer"><i class="fa-solid fa-trash" style={{ color: "red" }}></i></div>
                                                </div>
                                            :
                                            <div style={{ height: "140px" }} className="dropzone-wrapper dropzone-wrapper4 product-dropzone mb-0">
                                                <>
                                                    <div className="dropzone-desc">
                                                        <img src={imageIcon} alt="" />
                                                        <h6 className="mt-2 mb-0 text-gray text-drag">Drag and drop files, or <a className="text-primary decoration-none">click here to browse</a></h6>
                                                        <small className="text-muted-light text-drag-2">5MB max file size upload</small>
                                                    </div>
                                                    <input style={{height:"100px"}} type="file" accept=".png,.jpg,.jpeg" name="cover_image" className="dropzone dropzone4" onChange={handleChange} />
                                                </>
                                            </div>
                                        }
                                        {/* <FormFieldError error={error?.cover_image} /> */}

                                    </div>
                                    <div class="form-group">
                                        <label class="text-gray font-weight-500" style={{marginTop:"20px"}}>Post Content</label>
                                    </div>

                                    <div id="content-card">
                                        <div style={{ overflow: "hidden" }} className="d-flex align-items-center">
                                            <img src={emailIcon} width={30} />
                                            <span style={{marginLeft: "10px" ,overflow: "hidden", textOverflow: "ellipsis", fontSize:"14px" }}>{newsletterModalReducer?.subject !== "" || newsletterModalReducer?.subject ? ((newsletterModalReducer?.subject == '' || newsletterModalReducer?.subject == null) ? '[Post Headline]' : newsletterModalReducer?.subject) : "[Post Headline]"}</span>
                                        </div>
                                        <button type="button" style={{ whiteSpace: "nowrap" }} className="edit-content-btn me-2" onClick={openContentModal}>Edit content</button>
                                    </div>
                                    {/* {console.log("newsletterModalReducer?.channel",newsletterModalReducer?.channel)} */}
                                    { (newsletterModalReducer?.channel == 'post' || newsletterModalReducer?.channel == 'both') &&
                                        <>
                                            <div class="form-group mt-20px">
                                                <label class="text-gray font-weight-500" style={{marginTop:"20px"}}>Theme</label>
                                            </div>
                                            <div class="toggle-input-checkbox w-50">
                                                <input type="radio" id="text_align_left2" name="theme" onChange={handleChange} checked={newsletterModalReducer?.theme !== 'dark'} style={{ display: "none" }}  value={"light"} />
                                                <label for="text_align_left2" className="w-50"  > <span className='choose-plan-type-text'> Light</span></label>
                                                <input type="radio" id="text_align_right2" name="theme" onChange={handleChange} checked={newsletterModalReducer?.theme == 'dark'} style={{ display: "none" }} value={"dark"}  />
                                                <label for="text_align_right2" className="w-50 dark"   > <span className='dark'> Dark</span></label>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            <div class="accordion-item borderless mt-20px">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button btn borderless d-flex align-items-center collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sendNewsletterSchedule" aria-expanded="false" aria-controls="sendNewsletterScheduled">
                                        <img src={scheduleIcon} width="16px" height="14px" alt="" />
                                        <h5 class="mb-0 ms-2 font-weight-500">Schedule</h5>
                                    </button>
                                </h2>
                                <div id="sendNewsletterSchedule" class="accordion-collapse collapse" aria-labelledby="headingOne">

                                    <div class="form-group mt-4 mb-4">
                                        <div class="up-in-toggle bg-f1f1f1 w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                            <input type="radio" id="text_align_left" name="scheduleTime" defaultChecked={newsletterModalReducer?.scheduleTime == "sendnow" ? defaultCheck : false} style={{ display: "none" }} onChange={handleChange} value={"sendnow"} />
                                            <label for="text_align_left" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded " > {newsletterModalReducer?.channel == 'post'?'Publish ':'Send'} Now</label>
                                            <input type="radio" id="text_align_right" name="scheduleTime" defaultChecked={newsletterModalReducer?.scheduleTime == "schedule" ? defaultCheck : false} style={{ display: "none" }} value={"schedule"} onChange={handleChange} />
                                            <label for="text_align_right" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded "  > Schedule</label>
                                        </div>
                                        {(!newsletterModalReducer?.scheduleTime || newsletterModalReducer?.scheduleTime == "sendnow") ? '' :
                                            <div class="form-group mt-3 mb-3 row">
                                                <div className="col-6">
                                                    <label class="text-gray font-weight-500"><small>Schedule Newsletter</small></label>
                                                    <div class="schedule-time form-control zaap-form-control ms-1 date-filter-dropdown dropdown-toggle noselect new-title-inp "
                                                        id="date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span class="date-filter-dropdown-menu-selected font-weight-500">{newsletterModalReducer?.id === edit?.id ? finalDateString : today_text}</span>
                                                    </div>
                                                    <ul class="dropdown-menu date-filter-dropdown-menu" aria-labelledby="date-filter-dropdown" onClick={e => e.stopPropagation()}>
                                                        <li>
                                                            <div id="calendar-picker" name="send_date" required="required"  ></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-6">
                                                    <label class="text-gray font-weight-500"></label>
                                                    <div class="schedule-time form-control zaap-form-control ms-1 date-filter-dropdown dropdown-toggle noselect new-title-inp "
                                                        id="date-filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span class=" date-filter-dropdown-menu-selected-time font-weight-500" >{newsletterModalReducer?.id === edit?.id ? timeString : current_time}</span>
                                                    </div>
                                                    <ul class="dropdown-menu date-filter-dropdown-menu-time" aria-labelledby="date-filter-dropdown" onClick={e => e.stopPropagation()}>
                                                        <li>
                                                            <div id="time-picker" name="send_time" required="required"></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <small className="mt-2 text-danger d-flex align-items-center" style={{gap:"5px"}}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none"><path d="M5.254 5.2C5.39506 4.799 5.67349 4.46086 6.03997 4.24548C6.40645 4.0301 6.83734 3.95136 7.25631 4.02323C7.67527 4.09509 8.05529 4.31291 8.32905 4.63812C8.6028 4.96332 8.75263 5.37491 8.752 5.8C8.752 7 6.952 7.6 6.952 7.6M7 10H7.006M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z" stroke="#FF1451" stroke-linecap="round" stroke-linejoin="round"/></svg> <span className="time-u">Time zone in UTC+0</span></small>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {newsletterModalReducer.channel != "post" &&
                            <div class="accordion-item borderless mt-20px">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button btn borderless d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#sendNewsletterAudience" aria-expanded="false" aria-controls="sendNewsletterAudience">
                                        <img src={audienceIcon} width="16px" height="14px" alt="" />
                                        <h5 class="mb-0 ms-2 font-weight-500">Audience</h5>
                                    </button>
                                </h2>
                                <div id="sendNewsletterAudience" class="accordion-collapse collapse show" aria-labelledby="headingOne">
                                    <div class="form-group mt-4 mb-4 position-relative">
                                        <div className="edit-tag dropdown-toggle noselect" data-bs-toggle="dropdown" aria-expanded="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M14 7.33325L8.93726 2.27051C8.59135 1.92461 8.4184 1.75166 8.21657 1.62797C8.03762 1.51831 7.84254 1.43751 7.63846 1.38851C7.40829 1.33325 7.16369 1.33325 6.67452 1.33325L4 1.33325M2 5.79992L2 7.11626C2 7.44238 2 7.60544 2.03684 7.75889C2.0695 7.89494 2.12337 8.025 2.19648 8.1443C2.27894 8.27885 2.39424 8.39416 2.62484 8.62476L7.82484 13.8248C8.35286 14.3528 8.61687 14.6168 8.92131 14.7157C9.1891 14.8027 9.47757 14.8027 9.74536 14.7157C10.0498 14.6168 10.3138 14.3528 10.8418 13.8248L12.4915 12.1751C13.0195 11.6471 13.2835 11.383 13.3825 11.0786C13.4695 10.8108 13.4695 10.5224 13.3825 10.2546C13.2835 9.95013 13.0195 9.68611 12.4915 9.15809L7.62484 4.29142C7.39424 4.06082 7.27894 3.94552 7.14438 3.86307C7.02508 3.78996 6.89502 3.73609 6.75898 3.70343C6.60553 3.66659 6.44247 3.66659 6.11634 3.66659H4.13333C3.3866 3.66659 3.01323 3.66659 2.72801 3.81191C2.47713 3.93974 2.27316 4.14372 2.14532 4.3946C2 4.67981 2 5.05318 2 5.79992Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            Edit Tags
                                        </div>
                                        <ul className="project-new-dropdown dropdown-menu" aria-labelledby="bulk-add-tags-dropdown">
                                            {audienceTags?.map((value, index) => {
                                                const isChecked = newsletterModalReducer?.checked_tags?.some(tag => tag?.tag ?? tag === value?.tag);
                                                return (
                                                <li key={index} className="project-new-dropdown-item tags-option" onClick={(e) => e.stopPropagation()}>
                                                    <input
                                                        onChange={(e) => handleChange(e)}
                                                        className="tag-checked"
                                                        type="checkbox"
                                                        id={`tag-checkbox-bulk-${index}`}
                                                        style={{ display: 'none' }}
                                                        defaultChecked={isChecked}
                                                        name="checked_tags"
                                                        value={value?.tag}
                                                    />
                                                    <label 
                                                        onClick={(e) => e.stopPropagation()} 
                                                        style={{ backgroundColor: value?.color }} 
                                                        className="small-box" 
                                                        htmlFor={`tag-checkbox-bulk-${index}`}
                                                    />
                                                    <span 
                                                        onClick={() => {
                                                            const checkbox = document.getElementById(`tag-checkbox-bulk-${index}`);
                                                            if (checkbox) {
                                                                checkbox.click(); // Trigger checkbox click event
                                                            }
                                                        }} 
                                                        className={`cursor-pointer ms-2 ${isChecked ? 'checked-tag' : ''}`}
                                                    >
                                                        {value?.tag}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <div className="mt-1 d-flex align-items-center gap-1 flex-wrap">
                                        {
                                            newsletterModalReducer?.checked_tags?.map((val, index) => (
                                                <div className="edit-tag">
                                                    <span style={{ backgroundColor: val?.color ?? '#18f' }} className="small-box"></span>
                                                    <span>
                                                         {val?.tag ?? val}
                                                     </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {/* <div class="accordion-item borderless mt-2">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button btn borderless d-flex align-items-center collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sendNewsletterAnalytics" aria-expanded="false" aria-controls="sendNewsletterAnalytics">
                                        <img src={analyticsIcon} width="20px" alt="" />
                                        <h5 class="mb-0 ms-2 font-weight-500">Analytics</h5>
                                    </button>
                                </h2>
                                <div id="sendNewsletterAnalytics" class="accordion-collapse collapse" aria-labelledby="headingOne">
                                    <div class="form-group mt-3 px-3">
                                        <img src={analyticsMainIcon} width="100%" alt="" />
                                    </div>
                                </div>
                            </div> */}


                        </div>

                        <div class="send-newsletter-footer">
                            {newsletterModalReducer.channel != "post" ?
                            <button type="button" onClick={openEmailTestModal} class="close btn btn-semi-rounded me-2 d-flex align-items-center justify-content-center py-2 px-3">
                                <b class="me-2" style={{fontSize:"12px"}}>Send Test</b>
                                {/* <small class="badge bg-gray text-dark p-1">Esc</small> */}
                            </button>:''
                            }
                            <button type="button" class="btn btn-primary btn-semi-rounded d-flex align-items-center send-newsletter-btn justify-content-center py-2 px-3" onClick={handleSubmit}>
                                <b class="me-2 newbtn-u2">{newsletterModalReducer?.scheduleTime == "schedule" ? "Schedule " : "Send "}Now</b>
                                <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                    <img src={returnIcon} alt="" />
                                </small>
                            </button>
                        </div>

                    </div>
                    <CroppieModal
                        show={showCroppieModal}
                        handleHide={handleHideModal}
                        croppieClose={croppieClose}
                        croppedImage={croppedImage}
                        modalWidth={500}
                        postWidth={'postWidth'}
                    />
                </div>
            </Modal>
            {showModalasd && <PostsUpgradeModal showModal={showModalasd} upgradeHandleClick={upgradeHandleClick} setshowModal={setShowModalasd}/>}
            {/* {show && <div class="modal-backdrop fade show" onClick={removeModal}></div>} */}
            {showEmailTestModal && <EmailTestModal isOpen={showEmailTestModal} onHide={closeEmailTestModal} callNewsletter={callNewsletter} edit={editid} setIsEdit={setIsEdit} />}
            {showContentModal && <EditContentModal isOpen={showContentModal} onHide={closeContentModal} callNewsletter={callNewsletter} edit={editid} setIsEdit={setIsEdit} />}
            </>
    )
}
export default NewsletterModal