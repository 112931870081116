import PageHeader from "../components/page-header"
import analyticsInsta from "../assets/img/analytics-insta.svg"
import desktopIcon from "../assets/img/desktop-icon.svg";
import mobileIcon from "../assets/img/mobile-icon.svg";
import tabletIcon from "../assets/img/tablet-icon.svg";
import analyticsUp from "../assets/img/analytics-up.svg"
import analyticsDown from "../assets/img/analytics-down.svg"
import menuOverview from "../assets/img/menu-overview.svg"
import safariIcon from "../assets/img/safari-icon.svg"
import chromeIcon from "../assets/img/chrome-icon.svg"
import edgeIcon from "../assets/img/edge-icon.svg"
import chevron_down from "../assets/img/modals/chevron-down.svg"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "../assets/css/analytics.css"
import "../assets/calendar-plugin/css/mobiscroll.jquery.min.css"
import { useEffect, useRef, useState } from "react"
import { Line } from 'react-chartjs-2';
import Skeleton from "react-loading-skeleton"
import { months, originalOptions, originalData } from "../utils/analytics/line";
import "../assets/calendar-plugin/js/mobiscroll.jquery.min.js"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
} from 'chart.js';
import * as $ from "jquery";
import { hideLoader, showLoader } from "../components/loader"
import { apiUrl } from "../utils/url"
import { audiencePosts as statGets } from "../services/postRequests/audience"
import setDateFormat from "../utils/setDateFormat"
import { useDispatch, useSelector } from "react-redux";
import CommunityFooter from "../components/community/footer"
import Teammoadal from "../components/teammember/team_modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewSideBar from "../components/side-bar/new-sidebar";
// import * as flags from "../assets/img/flags"
import CustomTooltip from "../components/common/CustomTooltip.jsx";
import infoIcon from "../assets/img/info-black.svg"
import rightArrowIcon from "../assets/img/plain-right-arrow.svg"
import AnalyticsSession from "../components/analytics/analytics-session.jsx";
import FilterDropdowns from "../components/analytics/filter-dropdowns.jsx";
import alertIcon from "../assets/img/alert-circle.svg";
import AnalyticsMain from "../components/analytics/analytics-main.jsx";
import AnalyticsPosts from "../components/analytics/analytics-posts.jsx";
import AnalyticsHeader from "../components/analytics/analytics-header.jsx";
import AnalyticsLinks from "../components/analytics/analytics-links.jsx";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
);

let apiBasePath = "/project/statistics"


let graph_dates = new Date();
var labels = []

for (let index = 1; index < 10; index++) {
    graph_dates.setDate(graph_dates.getDate() - 1);
    labels.push(months[graph_dates.getMonth()] + ' ' + graph_dates.getDate());
}

const flagsPath = ""
let params = [];
let queryParams = []
export const calculateDateRange = (filterValue) => {
    const now = new Date();
    let from_date, to_date;

    switch (filterValue) {
        case '24h':
            from_date = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            break;
        case '48h':
            from_date = new Date(now.getTime() - 48 * 60 * 60 * 1000);
            break;
        case '7d':
            from_date = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            break;
        case '30d':
            from_date = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
            break;
        case 'all':
            from_date = new Date(0); // Starting date to cover all time
            break;
        default:
            return null;
    }
    to_date = now;
    return { from_date, to_date };
};
export const getOsIcon = (osName) => {
    const os = osName?.toLowerCase();
    switch (os) {
        case 'ios':
        case 'macos':
            return 'apple';
        default:
            return os || 'question-circle'; // Fallback icon if OS name is unknown
    }
};
const Analytics = () => {
    const analyticsPageName = window.location.pathname.split("/").pop();
    const dateFilters = [
        { label: 'Last 24 hrs', value: '24h' },
        { label: 'Last 48 hrs', value: '48h' },
        { label: 'Last 7 days', value: '7d' },
        { label: 'Last 30 days', value: '30d' },
        // { label: 'All Time', value: 'all' }
    ];
    const [loading, setloading] = useState(true);
    const history = useHistory()
    const [screenWidth, setscreenWidth] = useState(false)
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [topSources, settopSources] = useState(null)
    const [topCountries, settopCountries] = useState(null)
    const [devices, setdevices] = useState({})
    const [browsers, setbrowsers] = useState({})
    const [languages, setlanguages] = useState({})
    const [operatingSystems, setoperatingSystems] = useState({})
    const [activity, setactivity] = useState({})
    const secondPg = useRef(null);
    const [showModalasd, setShowModalasd] = useState(false)
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const planType = useSelector(state => state?.planTypeReducer);
    const dispatch = useDispatch();
    const [tab, setTab] = useState('overview');
    const [statsloading, setStatsloading] = useState(true);
    const [detailedAnalytics, setDetailedAnalytics] = useState(false);
    const [pageData, setPageData] = useState({pageHeading:analyticsPageName+' Analytics',pageDesc:`Gain detailed insights about your Zaap ${analyticsPageName}.`});
    const [defaultpageData, setDefaultPageData] = useState({pageHeading:analyticsPageName+' Analytics',pageDesc:`Gain detailed insights about your Zaap ${analyticsPageName}.`});
    const [date, setDate] = useState({});
    const [filter, setfilter] = useState({
        page: 1,
    })

    const [statsData, setstatsData] = useState({
        unique_visitors: {},
        impressions: {},
        link_clicks: {},
        revenue: {},
        click_through_rate: {},
    })

    const [graphData, setgraphdata] = useState({
        unique_visitors: {
            label: 'Unique Visitors',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#70EEFF',
            backgroundColor: 'rgba(112, 238, 255, 0.1)',
            tension: 0.1
        },
        impressions: {
            label: 'Impressions Visitors',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#18f',
            backgroundColor: 'rgba(17, 136, 255, 0.1)',
            tension: 0.1
        },
        time_on_page: {
            label: 'Visit Duration',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#7F48FB',
            backgroundColor: "rgba(127, 72, 251, 0.1)",
            tension: 0.1
        },
        link_clicks: {
            label: 'Link Clicks',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#FFC700',
            backgroundColor: "rgba(255, 199, 0, 0.1)",
            tension: 0.1
        },
        click_through_rate: {
            label: 'Click-Through-Rate',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#F00',
            backgroundColor: 'rgba(255, 0, 0, 0.1)',
            tension: 0.1
        },
    })
    useEffect(() => {
        setShowModalasd(!pro);
    }, [pro]);

    const [data, setData] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Unique Visitors',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#70EEFF',
                backgroundColor: 'rgba(112, 238, 255, 0.1)',
                tension: 0.1
            },
            {
                label: 'Impressions Visitors',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#18f',
                backgroundColor: 'rgba(17, 136, 255, 0.1)',
                tension: 0.1
            },
            {
                label: 'Visit Duration',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#7F48FB',
                backgroundColor: "rgba(127, 72, 251, 0.1)",
                tension: 0.1
            },
            {
                label: 'Link Clicks',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#FFC700',
                backgroundColor: "rgba(255, 199, 0, 0.1)",
                tension: 0.1
            },
            {
                label: 'Click-Through-Rate',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#F00',
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                tension: 0.1
            },
        ]
    })

    const callActivity = (_filter) => {
        showLoader()
        params = [];
        for (const key in _filter) {
            if (Object.hasOwnProperty.call(_filter, key)) {
                if (_filter[key])
                    params.push(`${[key]}=${_filter[key]}`)
            }
        };
        if (pro) {
            if (_filter?.page) {
                const formdata = new FormData();
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
                const setOptions = {
                    method: "GET",
                    headers: myHeaders,
                }
                statGets(null, apiUrl + apiBasePath + "/getActivityLog/" + localStorage.slug + queryParams + "&" + params?.join("&"), hideLoader, setOptions).then((response) => setactivity(response)).catch((err) => console.error(err))
            }
        }
    }

    useEffect(() => {
        if (window.screen.width < 500) {
             setscreenWidth(true)
        }
        setTimeout(() => {
            setloading(false)
            // renderCalendar();
        }, 1500);

    }, []);
    
    const getStats = (from_date, to_date) => {
        // setloading(true)
        setStatsloading(true);
        queryParams = []
        queryParams = "?from_date=" + from_date + "&to_date=" + to_date + "&type=" + analyticsPageName;
        if (pageData?.id) {
            queryParams += `&reference_id=${pageData.id}`;
        }
        for (const key in filter) {
            if (Object.hasOwnProperty.call(filter, key)) {
                if (filter[key])
                    queryParams += `&${key}=${filter[key]}`;
            }
        };
        setDate({ from_date: from_date, to_date: to_date })
        const formdata = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        let temp_graph_data = []
        statGets(null, apiUrl + apiBasePath + "/get/" + slug + params + queryParams, hideLoader, setOptions).then((response) => {
            setstatsData({ ...response })

            temp_graph_data.push(
                {
                    label: 'Unique Visitors',
                    fill: true,
                    borderColor: '#70EEFF',
                    backgroundColor: 'rgba(112, 238, 255, 0.1)',
                    tension: 0.1,
                    data: response?.graph_data?.unique_visitors
                },
                {
                    label: 'Impressions Visitors',
                    fill: true,
                    borderColor: '#18f',
                    backgroundColor: 'rgba(17, 136, 255, 0.1)',
                    tension: 0.1,
                    data: response?.graph_data?.impressions
                },
                {
                    label: 'Visit Duration',
                    fill: true,
                    borderColor: '#7F48FB',
                    backgroundColor: "rgba(127, 72, 251, 0.1)",
                    tension: 0.1,
                    data: response?.graph_data?.time_on_page,
                },
                {
                    label: 'Link Clicks',
                    fill: true,
                    borderColor: '#FFC700',
                    backgroundColor: "rgba(255, 199, 0, 0.1)",
                    tension: 0.1,
                    data: response?.graph_data?.link_clicks
                },
                {
                    label: 'Click-Through-Rate',
                    fill: true,
                    borderColor: '#F00',
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    tension: 0.1,
                    data: response?.graph_data?.click_rate
                },
            );

            setData({ labels: response?.graph_data?.labels, datasets: temp_graph_data });

            setgraphdata({
                unique_visitors: {
                    label: 'Unique Visitors',
                    data: response?.graph_data?.unique_visitors,
                    fill: true,
                    borderColor: '#70EEFF',
                    backgroundColor: 'rgba(112, 238, 255, 0.1)',
                    tension: 0.1
                },
                impressions: {
                    label: 'Impressions Visitors',
                    data: response?.graph_data?.impressions,
                    fill: true,
                    borderColor: '#18f',
                    backgroundColor: 'rgba(17, 136, 255, 0.1)',
                    tension: 0.1
                },
                time_on_page: {
                    label: 'Visit Duration',
                    data: response?.graph_data?.time_on_page,
                    fill: true,
                    borderColor: '#7F48FB',
                    backgroundColor: "rgba(127, 72, 251, 0.1)",
                    tension: 0.1
                },
                link_clicks: {
                    label: 'Link Clicks',
                    data: response?.graph_data?.link_clicks,
                    fill: true,
                    borderColor: '#FFC700',
                    backgroundColor: "rgba(255, 199, 0, 0.1)",
                    tension: 0.1
                },
                click_through_rate: {
                    label: 'Click-Through-Rate',
                    data: response?.graph_data?.click_rate,
                    fill: true,
                    borderColor: '#F00',
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    tension: 0.1
                },
            });
            if (response?.used_on) {
                setPageData({...pageData,used_on:response?.used_on,pageDesc:response?.page_desc,created_at:response?.created_at})
            }
            // statGets(null, apiUrl + apiBasePath + "/getDetailed/" + slug + queryParams, hideLoader, setOptions).then((response) => {
            settopSources(response?.top_sources)
            settopCountries(response?.top_countries);
            setdevices(response?.devices)
            // setlanguages(response?.languages)
            setoperatingSystems(response?.os)
            setbrowsers(response?.browsers)
            setStatsloading(false);
            // statGets(null, apiUrl + apiBasePath + "/getActivityLog/" + slug + queryParams, hideLoader, setOptions).then((response) => {
            //     setactivity(response)
            // }).catch((err) => console.error(err))
            // settopSources()
            // setloading(false)
        }).catch((err) => console.error(err))
        // setloading(false)
        // })
    }
    useEffect(() => {
        regenerateGraph();
    }, [graphData])

    function regenerateGraph() {
        let tempArr = []
        for (let index = 0; index < 4; index++) {
            if ($('input[name="analytics-graph-filter[]"]').eq(index).is(':checked')) {
                var checked_value = $('input[name="analytics-graph-filter[]"]').eq(index).val();
                if(graphData[checked_value]){
                    tempArr.push(graphData[checked_value]);
                }

            }
        }
        setData({
            labels: data?.labels,
            datasets: tempArr
        });

    }
    const analyticsGraphFilterClick = () => {
        // showLoader();
        
        if ($('input[name="analytics-graph-filter[]"]:checked').length == 4) {
            $(".filter-dropdown-menu-selected").text('All');
        }
        else if ($('input[name="analytics-graph-filter[]"]:checked').length > 0) {
            $(".filter-dropdown-menu-selected").text('Applied');
        }
        else {
            $(".filter-dropdown-menu-selected").text('None');
        }
        regenerateGraph();
    }

    const upgradeHandleClick = (e) => {
        e.preventDefault();
        // $(".upgrade-main-btn").click();
        if (newUser == 1) {
            history.push(basePath + '/settings/upgrade-zaap')
        } else {
            dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: false, open: true } });
        }
        // setShowModalasd(false);
    }

    function toggleTab(e) {
        const selectedValue = e.target.value;
        if (selectedValue == 'overview') {
            setPageData(defaultpageData);
            setDetailedAnalytics(false)
        }else if (selectedValue == 'sessions' && 'posts') {
            setPageData({...pageData,pageHeading: 'Select a post to view detailed analytics'});
            setDetailedAnalytics(false)
        }
        setTab(selectedValue);
    }
    const showDetailedAnalytics = (id,heading, desc, created_at) => {
        setDetailedAnalytics(true)
        setPageData({id: id, pageHeading: heading,pageDesc: desc, created_at:created_at})
    }
    const goBack = () => {
        setDetailedAnalytics(false)
        setPageData(defaultpageData)
    }
    return (
        <>
            <div id="wrapper">
                <PageHeader />
                <div class="analytics-main">
                    <NewSideBar from={"analytics"} />
                    {/* <div class="left-side-bar d-flex align-items-center flex-column justify-content-start">
                        <a href="" class="left-menu-item active">
                            <img src={menuOverview} width="20px" alt="" />
                            <p class="mb-0 ms-2">Overview</p>
                        </a>
                    </div> */}


                    <section class="analytics-section">
                        {showModalasd && <Teammoadal showModal={showModalasd} upgradeHandleClick={upgradeHandleClick} heading={'Upgrade to Unlock Analytics.'} text={' Analytics is a PRO only Feature, Subscribe to<br /> PRO to unlock Analytics'} isUltra={false} />}
                        
                        <AnalyticsHeader goBack ={goBack} pageData={pageData} toggleTab={toggleTab} tab={tab} setTab={setTab} screenWidth={screenWidth} loading={loading}/>
                        {screenWidth == false && (detailedAnalytics || tab == 'overview') ?
                            <AnalyticsMain loading={loading} getStats={getStats} topCountries={topCountries} statsloading={statsloading} setactivity={setactivity} activity={activity} date={date} dateFilters={dateFilters} statsData={statsData}  analyticsGraphFilterClick={analyticsGraphFilterClick} screenWidth={screenWidth} data={data} topSources={topSources} devices={devices} browsers={browsers} operatingSystems={operatingSystems} />
                            :
                            screenWidth == false ?
                            (
                                analyticsPageName == 'page' ?
                                <AnalyticsSession loading={loading} getStats={getStats} topCountries={topCountries} statsloading={statsloading} setactivity={setactivity} activity={activity} date={date} dateFilters={dateFilters} statsData={statsData} />
                                :
                                analyticsPageName == 'posts' ?
                                <AnalyticsPosts showDetailedAnalytics={showDetailedAnalytics}/>
                                : 
                                analyticsPageName == 'links' ?
                                <AnalyticsLinks showDetailedAnalytics={showDetailedAnalytics}/>
                                : null
                            )
                            :
                            null
                        }
                        {/* <div class={"section-footer d-flex align-items-center justify-content-between  d-md-flex"}>
                            <span class="text-muted">{activity?.paginated?.total} results</span>
                            {
                                filter?.page && (
                                    <div class="table-pagination analytics-table-pagination">
                                        {filter?.page !== 1 && <button onClick={(e) => {
                                            const _filter = { ...filter, page: filter?.page - 1 }
                                            setfilter({ ...filter, page: filter?.page - 1 })
                                            $(".table-pagination > .btn-light").removeClass("active");

                                            $(e.target).addClass('active');
                                            callActivity(_filter)
                                        }} class="btn btn-light btn-sm 1">{filter?.page - 1}</button>}

                                        <button onClick={(e) => {
                                            const _filter = { ...filter, page: filter?.page }
                                            setfilter({ ...filter, page: filter?.page })
                                            $(".table-pagination > .btn-light").removeClass("active");

                                            $(e.target).addClass('active');
                                            callActivity(_filter)
                                        }} class="btn btn-light btn-sm 2">{filter?.page}</button>

                                        <button ref={secondPg}
                                            onClick={(e) => {
                                                const _filter = { ...filter, page: filter?.page + 1 }
                                                setfilter({ ...filter, page: filter?.page + 1 })
                                                $(".table-pagination > .btn-light").removeClass("active");
                                                $(e.target).addClass('active');
                                                callActivity(_filter)
                                            }} class="btn btn-light btn-sm 3" disabled={filter?.page == activity?.paginated?.last_page}>{filter?.page + 1}</button>
                                        {filter?.page == 1 && <button

                                            onClick={(e) => {
                                                const _filter = { ...filter, page: filter?.page + 2 }
                                                setfilter({ ...filter, page: filter?.page + 2 })
                                                $(".table-pagination > .btn-light").removeClass("active");

                                                $(e.target).addClass('active');
                                                callActivity(_filter)
                                            }}
                                            disabled={activity?.activities?.length < 10 && filter?.page + 1 >= activity?.paginated?.last_page} class="btn btn-light btn-sm 4">{filter?.page + 2}</button>}
                                    </div>
                                )
                            }
                        </div> */}
                    </section>
                </div >

            </div >
        </>
    )
}
export default Analytics